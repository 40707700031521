import React from 'react'
import {GoLocation} from 'react-icons/go'
import {AiOutlineMail,AiFillCheckCircle} from 'react-icons/ai'
import {FaTiktok} from 'react-icons/fa'
import {ImFacebook} from 'react-icons/im'
import {BsTelephone,BsInstagram,BsTwitter,BsYoutube} from 'react-icons/bs'
import SlideFromBottom from '../Animations/SlideFromBottom'
import ElectronicsHeader from '../electronicsPageComponents/ElectronicsHeader'
import ElectronicsFooter from '../electronicsPageComponents/ElectronicsFooter'

const ContactUs = () => {
  return (
    <SlideFromBottom>
      <ElectronicsHeader/>
      <div className='w-full h-auto py-32 pt-[150px] flex justify-center bg-slate-100'>
        <div className='w-[80%] flex gap-[7%]'>
          <div className='w-[60%] flex flex-col px-10'>
            <div className='w-full h-[300px] '>
              <img className='w-full h-full' src={require('./aboutUsImage/map.png')} />
            </div>
            <div className='w-full h-auto flex px-3 py-10'>
              <div className='flex w-[33%] flex-col gap-4 items-center'>
                <div className='w-[30px] h-[40px]'>
                  <GoLocation className='w-full h-full'/>
                </div>
                <div className='flex flex-col items-center'> 
                  <h1 className='font-bold'>Location:</h1>
                  <p>165th St, Jamaica, NY 11</p>
                </div>
              </div>
              <div className='flex w-[33%] flex-col gap-4 items-center'>
                <div className='w-[30px] h-[40px]'>
                  <AiOutlineMail className='w-full h-full'/>
                </div>
                <div className='flex flex-col items-center'> 
                  <h1 className='font-bold'>Email:</h1>
                  <p>loremipsum@gmail.com</p>
                </div>
              </div>
              <div className='flex w-[33%] flex-col gap-4 items-center'>
                <div className='w-[30px] h-[40px]'>
                  <BsTelephone className='w-full h-full'/>
                </div>
                <div className='flex flex-col items-center'> 
                  <h1 className='font-bold'>Phone:</h1>
                  <p>+1-202-555-0158</p>
                </div>
              </div>
            </div>
            <div className='flex gap-5 justify-center'>
              <div className='w-[30px] h-[30px] text-blue-500 hover:text-black'>
                <BsInstagram className='w-full h-full '/>
              </div>
              <div className='w-[30px] h-[30px] text-blue-500 hover:text-black'>
                <ImFacebook className='w-full h-full '/>
              </div>
              <div className='w-[30px] h-[30px] text-blue-500 hover:text-black'>
                <FaTiktok className='w-full h-full '/>
              </div>
              <div className='w-[30px] h-[30px] text-blue-500 hover:text-black'>
                <BsTwitter className='w-full h-full '/>
              </div>
              <div className='w-[30px] h-[30px] text-blue-500 hover:text-black'>
                <BsYoutube className='w-full h-full '/>
              </div>
            </div>
          </div>
          <div className='w-[33%] h-auto flex flex-col gap-5'>
            <div className='flex flex-col gap-2'>
              <h1 className='font-bold'>Let’s Talk</h1>
              <p className='text-slate-500'>To request a quote our products, contact us directly or fill out the form and we will get back to you promptly.</p>
            </div>
            <div className=' flex flex-col gap-5'>
              <label className='flex flex-col'>
                Name
                <input className='py-1 border-b border-slate-300  outline-none bg-slate-100' placeholder='Enter Your First Name'/>
              </label>
              <label className='flex flex-col'>
                Email
                <div className='w-full flex border-b border-slate-300 justify-between'>
                  <input className='py-1 w-[90%] outline-none bg-slate-100' placeholder='Enter Your Email Hear'/>
                  <AiFillCheckCircle className='w-[25px] h-[25px]'/>
                </div>
              </label>
              <label className='flex flex-col'>
                Phone numbers
                <input className='py-1 border-b border-slate-300  outline-none bg-slate-100' placeholder='Phone numbers'/>
              </label>
              <label className='flex flex-col'>
                Comment
                <div className='w-full flex border-b border-slate-300 justify-between'>
                  <input className='py-1 w-[90%]  outline-none bg-slate-100' placeholder='Comment'/>
                  <AiFillCheckCircle className='w-[25px] h-[25px]'/>
                </div>
              </label>
            </div>
            <div className='flex px-[10%] justify-center items-center group'>
              <button className='w-full bg-blue-600 rounded-full text-xl text-white py-1 group-hover:bg-black '>send</button>
            </div>
          </div>
        </div>
      </div>
      <ElectronicsFooter/>
    </SlideFromBottom>
  )
}

export default ContactUs