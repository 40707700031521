import React from 'react'
import Slider from './Slider'
import Category from './Category'
import NewSale from './NewSale'
import ClotherSlider from './ClotherSlider'
import Recommended from './Recommended'
import CategoryLast from './CategoryLast'
import BrandSlider from './BrandSlider'
import OurLookbook from './OurLookbook'
// import PeopleSay from './PeopleSay'
import SlideFromBottom from '../Animations/SlideFromBottom'
import Header from '../Header'
import Footer from '../Footer'
import PopularSlider from './PopularSlider'
import SaleSlider from './SaleSlider'
import FirstClotherSlider from './FirstClotherSlider'
import CountdownTimer from './CountdownTimer'

const HomePage = () => {
  return (
    <div className="w-full h-auto bg-white ">
      <Header/>
      <Slider/>
      <Category/>
      <SlideFromBottom>
        <div className="flex flex-col gap-5 items-center">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl">ახალი დამატებული</h1>
          </div>
          <NewSale/>
          <FirstClotherSlider/>
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="flex flex-col gap-5 items-center py-20">
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl">ბავშვების კოლექცია</h1>
          </div>
          <NewSale/>
          <ClotherSlider/>
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="flex flex-col gap-16 items-center py-20">
          <div className="flex flex-col gap-5 justify-center items-center">
            <h1 className="text-2xl">ფასდაკლებული კოლექცია</h1>
            <CountdownTimer/>
          </div>
          <PopularSlider/>
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="flex flex-col gap-16 items-center py-20">
          <h1 className="text-2xl">პოპულარული კოლექცია</h1>
          <SaleSlider/>
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className="w-full h-auto flex flex-col items-center pt-10">
          <div className='flex flex-col gap-3'>
            <h1 className="text-2xl  capitalize">რეკომენდირებული კოლექცია</h1>
          </div>
          <Recommended/>
        </div>
      </SlideFromBottom>
      <CategoryLast/>
      <SlideFromBottom>
        <div className="w-full h-auto py-16 px-[10%] max-2xl:px-[10%] max-xl:px-5 bg-slate-100 flex flex-col gap-[55px] items-center">
          <div className='flex flex-col items-center'>
            <h1 className='text-3xl'>ბრენდები</h1>
          </div>
          <BrandSlider/>
        </div>
      </SlideFromBottom>
      <OurLookbook/>
      {/* <SlideFromBottom>
        <div className="w-full h-auto py-24 px-[15%] bg-slate-100 flex flex-col gap-5 items-center">
          <div className='flex flex-col gap-3 items-center'>
            <h1 className='font-black text-xl'>What People Says</h1>
            <p>Real customer reviews from themeforest.net</p>
          </div>
          <PeopleSay/>
        </div>
      </SlideFromBottom> */}
      <Footer/>
    </div>
  )
}

export default HomePage