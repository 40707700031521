import { useState } from "react";
import Router from "./router/Router";
import ScrollBarVisible from "./contextComponents/ScrollBarVisible";
import CartShow from "./contextComponents/CartShow";
import MainPopUpShow from "./contextComponents/MainPopUpShow";
import CartPopUp from "./components/CartPopUp";
import CartData from "./contextComponents/CartData";
import Test1 from "./components/axiosFolder/test/Test1";
import MainPopUp from "./components/MainPopUp";

function App() {
  const [scrollingStop, setScrollingStop ] = useState(false)
  const [cartIsShow, setCartIsShow ] = useState(false)
  const [isMainPopUpShow, setIsMainPopUpShow ] = useState(true)
  const [cartData , setCartData] = useState([])
  return (
    <>
    <MainPopUpShow.Provider value={{isMainPopUpShow,setIsMainPopUpShow}}>
      <CartData.Provider value={{cartData,setCartData}}>
        <CartShow.Provider value={{cartIsShow, setCartIsShow}}>
          <ScrollBarVisible.Provider value={{scrollingStop, setScrollingStop}}>
          <div className={`${scrollingStop===false ? 'overflow-hidden' : ''}`}>
            <Router/>
            {cartIsShow && <CartPopUp/>}
            {isMainPopUpShow && <MainPopUp setIsMainPopUpShow={setIsMainPopUpShow}/>}
          </div>
          </ScrollBarVisible.Provider>
        </CartShow.Provider>
      </CartData.Provider>
    </MainPopUpShow.Provider>
    </>
  );
}

export default App;
