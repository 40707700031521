import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import '../../style/main.css'

const BrandSlider = () => {
  return (
    <div className="w-full h-auto">
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="clotherSwiper w-full h-[400px]"
      >
        <SwiperSlide>
          <div className="flex flex-col gap-7 w-full h-[400px]">
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300  h-full ' src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-cabian-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-cole-hoon-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-saucany-home.png?v=1676626140&width=303' alt=''/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-7 w-full h-[510px]">
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'  src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-canvers-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-colvin-klein-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-under-armor-home.png?v=1676626140&width=303' alt=''/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-7 w-full h-[510px]">
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-claytan-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-dacoste-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-cole-hoon-home_1.png?v=1676626140&width=303' alt=''/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-7 w-full h-[510px]">
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-clorks-home.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-koach-home.png?v=1676626141&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-cabian-home_1.png?v=1676626140&width=303' alt=''/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col gap-7 w-full h-[510px]">
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-saucany-home2.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-canvers-home2.png?v=1676626140&width=303' alt=''/>
            </div>
            <div className="w-full h-[100px] group shadow-inherit rounded-xl border bg-[#ffffff] flex justify-center">
              <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-2xl group-hover:rounded-xl duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-dacoste-home2.png?v=1676626140&width=303' alt=''/>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default BrandSlider