import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import '../../../style/main.css'
import BootsComponent from "./BootsComponent";

// import { BootsComponent } from "./BootsComponent";

const Boots = () => {
  const [data,setData] = useState([
    {
      id: 1,
      title: "ბოტასი",
      name: "კაცის ბოტასი",
      price: '47',
      size: ['6','6.5','7','7.5','8','8.5','9'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_0df8d1b8-56e4-4103-b85b-b342287c0ed8.png?v=1676381750&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_70774715-e8f0-4a31-a400-2e85a773f980.png?v=1676381763&width=357'
    },
    {
      id: 2,
      title: "ბოტასი",
      name: "კაცის ბოტასი",
      price: '47',
      size: ['6','6.5','7','7.5','8','8.5','9'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_30f66a3a-d1bc-4e73-bd2a-e713f026ab9c.png?v=1676383910&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_c3d9697b-11ad-46ad-bc47-d0255d22b412.png?v=1676908947&width=357'
    },
    {
      id: 3,
      title: "ბოტასი",
      name: "კაცის ბოტასი",
      price: '47',
      size: ['6','6.5','7','7.5','8','8.5','9'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_727e6d7a-292b-47e2-9631-f871afd680f7.png?v=1676383683&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_c77e7289-e768-4529-82b2-2f416aafa0db.png?v=1676383683&width=357'
    },
    {
      id: 4,
      title: "ბოტასი",
      name: "კაცის ბოტასი",
      price: '47',
      size: ['6','6.5','7','7.5','8','8.5','9'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_b65e6aeb-ea2d-436a-8123-5788f118c23e.png?v=1676383275&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e649a834-4c08-4628-895a-03793883ca6a.png?v=1676383274&width=357'
    }
  ])
  return (
    <div className="w-full h-auto px-[13%]">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="bootsSwiper w-full h-[600px]"
      >
        {
          data.map((item) => (
            <SwiperSlide 
              key={item.id}
              className="w-[23%]">
              <BootsComponent item={item} />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

export default Boots