import React from 'react'
import {IoMdArrowDropright} from 'react-icons/io'

const MenDivs = () => {
  return (
    <div className='w-0 h-[500px] duration-500  rounded-sm   group-hover:w-full absolute left-[100%] top-0 z-[5]'>
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col bg-white w-full h-full overflow-hidden rounded-xl' >
          <div className='w-full flex flex-col py-7 ml-10'>
            <h1 className='hover:text-blue-600 cursor-pointer'>ფეხსაცმელი</h1>
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright />
              <p className='text-sm'>ჯინსი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>შარვალი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center  gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>შორტი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>მაისური</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პერანგი / ბლუზი</p>
            </div>
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ქურთუკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პიჯაკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პერანგი / ბლუზი</p>
            </div>
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ქურთუკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პიჯაკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პერანგი / ბლუზი</p>
            </div>
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ქურთუკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პიჯაკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პერანგი / ბლუზი</p>
            </div>
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ქურთუკი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
            </div>    
            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>პიჯაკი</p>
            </div>    
            <div
              className='flex justify-between items-center px-7 '>
              <div
                className={` absolute h-[70%] w-0 group-hover:w-full  duration-500  z-[6] left-[101%] top-0 `}>
                <div className={`flex flex-col  overflow-hidden w-full h-full bg-white rounded-xl`}>
                  <div className='w-full flex flex-col py-7 pl-10'>
                    <h1 className='hover:text-blue-600 cursor-pointer'>ტანსაცმელი</h1>
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright />
                      <p className='text-sm'>ჯინსი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>შარვალი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center  gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>შორტი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>მაისური</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>პერანგი / ბლუზი</p>
                    </div>
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>ქურთუკი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>ჟილეტი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>პიჯაკი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>პერანგი / ბლუზი</p>
                    </div>
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>ქურთუკი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>ჟილეტი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>პიჯაკი</p>
                    </div>    
                    <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                      <IoMdArrowDropright/>
                      <p className='text-sm'>პერანგი / ბლუზი</p>
                    </div>     
                    <div
                      className='flex justify-between items-center px-7'>
                      <div
                        className={` absolute h-[70%] w-0 group-hover:w-full duration-500  z-[7] left-[101%] top-0 `}>
                        <div className={`flex flex-col overflow-hidden w-full h-full bg-white rounded-xl`}>
                          <div className='w-full flex flex-col pl-10 p-7'>
                            <h1 className='hover:text-blue-600 cursor-pointer'>აქსესუარები</h1>
                            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright />
                              <p className='text-sm'>ჯინსი</p>
                            </div>    
                            <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright/>
                              <p className='text-sm'>შარვალი</p>
                            </div>    
                            <div className='cursor-pointer flex hover:text-blue-600 items-center  gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright/>
                              <p className='text-sm'>შორტი</p>
                            </div>    
                            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright/>
                              <p className='text-sm'>მაისური</p>
                            </div>    
                            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright/>
                              <p className='text-sm'>პერანგი / ბლუზი</p>
                            </div>
                            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright/>
                              <p className='text-sm'>ქურთუკი</p>
                            </div>    
                            <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                              <IoMdArrowDropright/>
                              <p className='text-sm'>ჟილეტი</p>
                            </div>    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenDivs