import React, { useRef } from 'react'
import {BsFillArrowLeftCircleFill,BsFillArrowRightCircleFill} from 'react-icons/bs'

const SliderNavigationButton = () => {
  let swiperRef = useRef()
  return (
    <div className='w-full absolute flex items-center justify-between '>
      <button className='w-9 h-9 rounded-full bg-white text-black'
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <BsFillArrowLeftCircleFill/></button>
      <button className='w-9 h-9 rounded-full bg-white text-black'
        onClick={() => swiperRef.current?.slideNext()}
      ><BsFillArrowRightCircleFill/></button>
    </div>
  )
}

export default SliderNavigationButton