import React, { useState } from 'react'
import {BsArrowRightShort} from 'react-icons/bs'
import ChildrenHoverClother from './ChildrenHoverClother'
import ChildrenHoverShoes from './ChildrenHoverShoes'
import ChildrenHoverAccessories from './ChildrenHoverAccessories'


const ChildrenHover = () => {
  const [typeCloth,setTypeCloth] = useState('clother')
  return (
    <div className="w-[60%] overflow-hidden h-0 bg-opacity-80 bg-white flex justify-between absolute mt-14 left-[20%] rounded-lg opacity-0 group-hover:opacity-100 group-hover:h-[300px]  duration-500"> 
      <div className='w-[29%] h-full flex gap-5 '>
        <div className='flex w-full flex-col h-full text-black  bg-opacity-80'>
          <div
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='clother' ? 'text-blue-600':''} cursor-pointer`}
            onMouseOver={() => setTypeCloth('clother')}
            >
            <h1
              className='text-sm pl-2'>ბავშვის ტანსაცმელი</h1>
            <BsArrowRightShort/>
          </div>
          <div 
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='shoes' ? 'text-blue-600':''} cursor-pointer`}
            onMouseOver={() => setTypeCloth('shoes')}
            >
            <h1
              className='text-sm pl-2'>ბავშვის ფეხსაცმელი</h1>
            <BsArrowRightShort/>
          </div>
          <div 
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='accessories' ? 'text-blue-600':''} cursor-pointer`}
            onMouseOver={() => setTypeCloth('accessories')}
            >
            <h1 className='text-sm pl-2'>ბავშვის აქსესუარები</h1>
            <BsArrowRightShort/>
          </div>      
        </div>
      </div>
        {typeCloth === 'clother' && <ChildrenHoverClother/>}
        {typeCloth === 'shoes' && <ChildrenHoverShoes/>} 
        {typeCloth === 'accessories' && <ChildrenHoverAccessories/>} 
    </div>
  )
}

export default ChildrenHover