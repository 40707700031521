import React from 'react'

const Brands = () => {
  return (
    <div className='w-full flex justify-center items-center px-[10%] h-auto '>
      <div className='w-full h-auto flex justify-between'>
        <div className='flex justify-center items-center w-[15%] h-auto'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-berberry-home.png?v=1676901675&width=303' alt=''/>
        </div>
        <div className='flex justify-center items-center w-[15%] h-auto'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-hermus-home.png?v=1676901597&width=303' alt=''/>
        </div>
        <div className='flex justify-center items-center w-[15%] h-auto'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-kemzo-home.png?v=1676901500&width=303' alt=''/>
        </div>
        <div className='flex justify-center items-center w-[15%] h-auto'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-levies-home.png?v=1676901500&width=303' alt=''/>
        </div>
        <div className='flex justify-center items-center w-[15%] h-auto'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-monkler-home.png?v=1676901500&width=303' alt=''/>
        </div>
        <div className='flex justify-center items-center w-[15%] h-auto'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-primarc-home.png?v=1676901500&width=303' alt=''/>
        </div>
      </div>
    </div>
  )
}

export default Brands