import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ContactUs from '../components/contactPageComponents/ContactUs';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomePage from '../components/homePage/HomePage';
import Electronics from '../components/electronicsPageComponents/Electronics';
import HomePageThird from '../components/homePageThird';
import CategoryClotherPage from '../components/categoryClotherPage';
import CategoryId from '../components/categoryIdComponents/CategoryId';

const Router = () => {
  return (
    <>
    {/* <Header/> */}
    <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="category/:category" element={<CategoryClotherPage/>}/>
      <Route path="category/:category/:id" element={<CategoryId/>}/>
      <Route path="aboutUS" element={<ContactUs/>}/>
      <Route path="electronics" element={<Electronics/>}/>
      <Route path="homePageThird" element={<HomePageThird/>}/>
    </Routes>
    {/* <Footer/> */}
    </>
  )
}

export default Router