import React, { useContext, useEffect, useState } from 'react'
import Counter from './Counter';
import {BsXLg} from 'react-icons/bs'
import {TbCurrencyLari} from 'react-icons/tb'
import ScrollBarVisible from '../../contextComponents/ScrollBarVisible';
import CartShow from '../../contextComponents/CartShow';
import CartData from '../../contextComponents/CartData';

const RecommendedPopUp = ({setPopUpShow,selectedItem}) => {
  const [colorValue, setColorValue] = useState("წითელი");
  const [def,setDef] = useState(1)
  // const[render,setRender] = useState(1)
  const [counterValue1, setCounterValue1] = useState(1)
  const cartShow = useContext(CartShow)
  const cartData = useContext(CartData)
  const [sizeValue, setSizeValue] = useState("");
  // const [imgChange, setImgChange] = useState(selectedItem.src);
  useEffect( () => {
    const items=localStorage.getItem('items')
    if(items) {
      cartData.setCartData(
        JSON.parse(localStorage.getItem('items'))
        )
    }
  },[])
  const addCart = (items) =>{
    if(counterValue1>0){
      const filtered=cartData.cartData.find((item) => item.id===items.id)
      if(filtered)
      {
        // if((colorValue !== filtered.colorValue) && (sizeValue !== filtered.sizeValue)){
        //   cartData.cartData.map((item)=>{
        //     if(items.id===item.id)
        //     {
        //       item.counter=counterValue1+item.counter
        //     }
        //   })
        //     localStorage.setItem('items',JSON.stringify(cartData.cartData))
        // }
        // else{
        //   const newCart=[{counter:counterValue1, colorValue:colorValue ,sizeValue:sizeValue, ...items},...cartData.cartData]
        //   cartData.setCartData(newCart)
        //   localStorage.setItem('items',JSON.stringify(newCart))
        // }
        cartData.cartData.map((item)=>{
          if(items.id===item.id)
          {
            item.counter=counterValue1+item.counter
          }
        })
          localStorage.setItem('items',JSON.stringify(cartData.cartData))
      }
      else
      {
        const newCart=[{counter:counterValue1, colorValue:colorValue ,sizeValue:sizeValue, ...items},...cartData.cartData]
        cartData.setCartData(newCart)
        localStorage.setItem('items',JSON.stringify(newCart))
      }
      // console.log(items.colorValue)
      // console.log(filtered.colorValue)
      // console.log(filtered)
    }
  }

  return (
    <div className='w-screen top-0 left-0 h-screen flex justify-center items-center fixed bg-black bg-opacity-80 z-50'>
      <div 
        className='w-[35%] h-[70%] flex flex-col gap-7 bg-white border-2 border-slate-500 px-[50px] py-[50px] rounded-xl '>
          <div className='flex justify-between items-center text-black'>
            <h1 className='text-2xl'>{selectedItem.name}</h1>
            <BsXLg
              onClick={() => setPopUpShow(false)}
              className='w-7 h-7 cursor-pointer'/>
          </div>
          <div className="flex items-center">
            <div className='w-5 h-5 flex justify-center items-center font-thin'>
              <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
            </div>
            <h1 className="font-bold text-lg">{selectedItem.price}</h1>
          </div>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-col'>
              <h1 className="mb-[1px] text-[12px]">ფერი:{colorValue}</h1>
              <div className="flex gap-1">
                {selectedItem.color.map((item) => (
                  <div
                    key={item.id}
                    onMouseOver={() => {
                      setColorValue(item.name);
                      setSizeValue("");
                    }}
                    onClick={() => {
                      setColorValue(item.name);
                      setSizeValue("");
                    }}
                    className={`border-2 bg-${item.name1}-600 shadow-md ${
                      colorValue === item.name
                        ? ""
                        : ""
                    } w-10 flex justify-center items-center rounded-md h-10 `}
                  >
                  </div>
                ))}
              </div>
            </div>
            <div className='flex flex-col'>
            <h1 className="mb-[1px] text-[12px]">ზომა:{sizeValue}</h1>
            <div className="flex gap-1">
              {selectedItem.color
                .filter((item) => item.name === colorValue)[0]
                .size.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => {
                      setSizeValue(item);
                    }}
                    className={`border-[2px] px-[5px] flex justify-center items-center rounded-md py-[1px] ${item.length > 4?"":'w-[30px]'}
                    shadow-md
                    ${
                      sizeValue === item
                        ? "border-blue-500"
                        : "border-slate-200"
                    }`}
                  >
                    <p className='text-[12px]'>{item}</p>
                  </button>
                ))}
            </div>
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='w-[30%] border-b-[1px] border-slate-300 flex justify-center px-[10px]'>
              <Counter setValue={setCounterValue1} test1={def} test={false}/>
            </div>
            <button
                onClick={() =>{
                  cartShow.setCartIsShow(true);
                  addCart(selectedItem);
                  setDef((prev) => prev+1);
                }}
              className='justify-center w-[65%] text-sm items-center bg-blue-700 text-white  rounded-full py-[10px]'>
                კალათაში დამატება
            </button>
          </div>
          <p
            className='text-xs text-slate-500 py-3'
          >შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან</p>
      </div>
    </div>
  )
}

export default RecommendedPopUp