import React from 'react'
import {BsArrowRightShort} from 'react-icons/bs'
import {IoMdArrowDropright} from 'react-icons/io'

const WomenDivs = () => {
  return (
    <div className='w-0 h-[500px] duration-500  rounded-lg   group-hover:w-full absolute left-[100%] top-0 z-[5]'>
      <div className='flex flex-col w-full h-full'>
        <div className='flex flex-col bg-white w-full h-full overflow-hidden rounded-xl' >
          <div className='w-full flex flex-col py-7'>
            <div className='flex flex-col pl-10'>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პიჯაკი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პერანგი / ბლუზი</p>
              </div>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
              </div>  
              {/* {//////  } */}
              <div
                className='flex justify-between items-center group-one '>
                <div className='flex gap-2'>
                  <IoMdArrowDropright/>
                  <h1 className='text-xs'>აქსესუარები</h1>
                </div>
                <BsArrowRightShort className='text-black w-7 h-7 mr-10 '/>
                <div
                  className={` absolute h-[70%] w-0 group-one-hover:w-full  duration-500  z-[6] left-[101%] top-[20%]`}>
                  <div className={`flex flex-col overflow-hidden w-full h-full bg-white rounded-xl`}>
                    <div className='w-full flex flex-col py-7'>
                      <div className='flex flex-col pl-10 '>
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>ჟილეტი</p>
                        </div>
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>პიჯაკი</p>
                        </div> 
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>პერანგი / ბლუზი</p>
                        </div>
                        <div
                          className='flex items-center justify-between group-two'>
                          <div className='flex gap-2'>
                            <IoMdArrowDropright/>
                            <h1 className='text-xs'>სამკაულები</h1>
                          </div>
                          <BsArrowRightShort className='text-black w-7 h-7 mr-10 '/>
                          <div
                            className={` absolute h-[70%] w-0 group-two-hover:w-full duration-500  z-[7] left-[101%] top-[20%] `}>
                            <div className={`flex flex-col overflow-hidden w-full h-full bg-white rounded-xl`}>
                              <div className='flex flex-col pl-10 py-10'>
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>ჟილეტი</p>
                                </div>
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>პიჯაკი</p>
                                </div> 
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>პერანგი / ბლუზი</p>
                                </div>
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>ჟილეტი</p>
                                </div>    
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>პიჯაკი</p>
                                </div> 
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>პერანგი / ბლუზი</p>
                                </div>
                                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                                  <IoMdArrowDropright/>
                                  <p className='text-sm'>ჟილეტი</p>
                                </div>    
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>ჟილეტი</p>
                        </div>    
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>პიჯაკი</p>
                        </div>    
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>პიჯაკი</p>
                        </div> 
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>პერანგი / ბლუზი</p>
                        </div>
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>ჟილეტი</p>
                        </div>    
                        <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                          <IoMdArrowDropright/>
                          <p className='text-sm'>პიჯაკი</p>
                        </div>    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პიჯაკი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პერანგი / ბლუზი</p>
              </div>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პიჯაკი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პერანგი / ბლუზი</p>
              </div>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პიჯაკი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პერანგი / ბლუზი</p>
              </div>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პიჯაკი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პერანგი / ბლუზი</p>
              </div>
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>ჟილეტი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პიჯაკი</p>
              </div>    
              <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
                <IoMdArrowDropright/>
                <p className='text-sm'>პერანგი / ბლუზი</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WomenDivs