import React from 'react'
import Select from '../../homePage/ClotherType/Select';
import { BsBasket } from 'react-icons/bs';
import Counter from '../../homePage/Counter';

const HoverStyle2 = ({item,colorValue,setColorValue,sizeValue,setSizeValue,cartShow,addCart,def,setDef,setCounterValue1}) => {
  const dataColor=['blue','red', 'yellow','grey']
  const dataSize=['xs','s', 'm','l']
  return (
    <div className="w-full h-0 group-hover:h-[55%] py-3 gap-4 rounded-lg border border-slate-200  bg-white absolute mb-0  
      flex-col items-center flex opacity-0 group-hover:opacity-100  overflow-hidden duration-500">
        <h1 className="capitalize font-semibold mt-3 text-[12px] opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">{item.name}</h1>
        <div className="flex flex-col opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
          <h1 className="text-center mb-[1px] text-[12px]">color:{colorValue}</h1>
          <Select name='grey' test={dataColor} setValue1={setColorValue}/>
        </div>
        <div className="flex flex-col opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
          <h1 className="text-center mb-[1px] text-[12px]">Size:{sizeValue}</h1>
          <Select name='xs' test={dataSize} setValue1={setSizeValue}/>
        </div>
        <div className='flex w-full items-center justify-evenly px-5'>
          <div className="w-[53%] flex flex-col  opacity-0 items-center justify-center group-hover:opacity-100 ease-linear delay-300 duration-200">
            <div className="w-full bg-blue-600 rounded-full h-auto flex gap-2  items-center justify-center ">
              <BsBasket className="text-white"/>
              <button
                onClick={() =>{
                  cartShow.setCartIsShow(true);
                  addCart(item);
                  setDef((prev) => prev+1);
                }}
                // onClick={() => {
                //   setPopUpShow(true);
                //   setSelectedItem(item);
                // }}
                className="text-sm  text-center py-[10px] text-white">
                დამატება
              </button>
            </div>
          </div>
          <div className="w-[30%]">
            <Counter setValue={setCounterValue1} test1={def} test={false} />
          </div>
        </div>
      </div>
  )
}

export default HoverStyle2