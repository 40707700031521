import React, { useContext, useEffect, useState } from 'react'
import CartShow from '../contextComponents/CartShow'
import CartData from '../contextComponents/CartData'
import Counter from './homePage/Counter'
import {RiDeleteBinLine} from 'react-icons/ri'
import {TbCurrencyLari} from 'react-icons/tb'
import {BsPen} from 'react-icons/bs'
import {RxUpdate} from 'react-icons/rx'
import Select from './homePage/ClotherType/Select'

const CartPopUp = () => {
  const cartIsShow = useContext(CartShow)
  const cartData= useContext(CartData)
  const [showUpdate,setShowUpdate] = useState(false)
  const [colorSelectValue,setColorSelectValue] = useState()
  const [def,setDef] = useState(1)
  const [sizeSelectValue,setSizeSelectValue] = useState()
  const dataColor=['ლურჯი','წითელი', 'ყვითელი','ნაცრისფერი']
  const dataSize=['xs','s', 'm','l']
  // const [counterObjValue,setCounterObjValue] = useState(1)
  const [updateCounter,setUpdateCounter] = useState(1)
  const clearCart = () => {
		const newCart=[]
		cartData.setCartData(newCart)
		localStorage.setItem('items',JSON.stringify(newCart))
  }
  const productDelete = (Id) =>{
    const newCart=cartData.cartData.filter((item)=> Id !== item.id )
    cartData.setCartData(newCart)
    localStorage.setItem('items',JSON.stringify(newCart))
  }
  const updateData = (item1) => {
    const newData=[...cartData.cartData]
    newData.map((item)=>{
      if(item1.id===item.id)
      {
        item.counter=updateCounter
        item.colorValue=colorSelectValue
        item.sizeValue=sizeSelectValue
      }
    })
    cartData.setCartData(newData)
    localStorage.setItem('items',JSON.stringify(cartData.setCartData))
  }
  return (
    <div className='w-screen top-0 left-0 h-screen flex justify-end fixed bg-black bg-opacity-60 z-50'>
      <div 
        className='w-[37%] h-full flex flex-col gap-7 rounded-xl bg-white border-2 border-slate-500 px-[50px] py-[50px] '>
          <div className='flex justify-between items-baseline'>
            <h1 className='text-2xl'>კალათა</h1>
            <h1
              className='text-xl mr-10'
              onClick={() => cartIsShow.setCartIsShow(false)}
            >
              X
            </h1>
          </div>
          <div className='flex flex-col gap-3 overflow-auto rounded-xl border border-slate-200'>
          {
            cartData.cartData.map((item) => (
              <div  
                key={item.id}
                className='flex w-full h-auto '>
                <div className={`w-[85%] ${showUpdate ? "h-[250px] items-start" :'h-auto'}  shadow-2xl p-[20px] flex white border border-slate-200 rounded-xl gap-5`}>
                  <div className='w-[38%] h-[155px]'>
                    <img
                      className='rounded-xl w-full h-full' 
                      src={item.img} alt=''/>
                  </div>
                  <div className='w-[60%] h-auto flex flex-col  justify-between '>
                    <div className={`w-full h-full ${showUpdate ? "hidden" :'flex'} flex flex-col  justify-between`}>
                      <div className={` flex flex-col gap-2`}>
                        <h1>{item.name}</h1>
                        <div className='flex flex-col'>
                          <p className='text-xs'>ფერი:{item.colorValue}</p>
                          <p className='text-xs'>ზომა:{item.sizeValue}</p>
                        </div>
                      </div>
                      <div className='flex'>
                      <div className="flex items-center">
                        <div className='w-5 h-5 flex justify-center items-center font-thin'>
                          <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
                        </div>
                          <h1 className="font-bold text-lg">{item.price * item.counter}</h1>
                      </div>
                        <div className='ml-8 text-xs'>
                          <Counter items={item}  def={item.counter} del={productDelete}/>
                        </div>
                      </div>
                    </div>
                    <div className={`w-full h-full ${showUpdate ? "flex" :'hidden'}  flex-col  gap-3`}>
                      <div className={` flex flex-col gap-2`}>
                        <h1>{item.name}</h1>
                        <Select setValue1={setColorSelectValue} name={item.colorValue} test={dataColor}/>
                        <Select setValue1={setSizeSelectValue} name={item.sizeValue} test={dataSize}/>
                      </div>
                      <div className='flex'>
                        <div className="flex items-center">
                          <div className='w-5 h-5 flex justify-center items-center font-thin'>
                            <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
                          </div>
                          <h1 className="font-bold text-lg">{item.price}</h1>
                        </div>
                        <div className='w-[30%]'>
                          <Counter setValue={setUpdateCounter} test1={def} def={1} />
                        </div>
                      </div>
                      <div className='w-full flex justify-center'>
                        <button 
                          onClick={() => {
                            updateData(item);
                            setShowUpdate(false);
                            setDef((prev) => prev+1);
                          }}
                          className='flex w-[60%] text-sm text-white gap-2 bg-blue-600 items-center justify-center rounded-full py-[8px] px-55'>
                          <RxUpdate />
                          განახლება
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex w-[15%] flex-col justify-center items-center gap-5'>
                  <div 
                    onClick={() =>productDelete(item.id)}
                    className='w-10 text-slate-500 h-10 rounded-full bg-slate-300 flex justify-center items-center'>
                    <RiDeleteBinLine className='w-[50%] h-[50%]'/>
                  </div>
                  <div
                    className='w-10 text-slate-500 h-10 rounded-full bg-slate-300 flex justify-center items-center'>
                    <BsPen
                      onClick={() =>setShowUpdate((prev) =>!prev )}
                      className='w-[50%] h-[50%]'/>
                  </div>
                </div>
              </div>
            ))
          }
          </div>
          {/* { 
            cartData.cartData.length !== 0 ?
            <div onClick={() => clearCart()}>გასუფთავება</div>:
            <div>ცარიელია</div>
          } */}
          <div onClick={() => clearCart()}><h1>გასუფთავება</h1></div>
      </div>
    </div>
  )
}

export default CartPopUp