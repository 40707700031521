import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import CartData from '../../contextComponents/CartData'
const Counter = ({def=1,test=true,setValue, items,test1,del}) => {
  const [counter, setCounter] = useState(def)
  const cartData= useContext(CartData)
  useEffect(()=>{
    if(setValue)
    {
      setValue(counter)
    }
  },[counter])
  useEffect(()=>{
    if(test1)
    {
      setCounter(1)
    }
  },[test1])
  const handelIncriment = () => {
    setCounter((prev) => prev+1)
    if(items){
      const newCart = [...cartData.cartData]
      newCart.map((item)=>{
        if(items.id===item.id)
        {
          item.counter=item.counter+1
        }
      })
      cartData.setCartData(newCart)
      localStorage.setItem('items',JSON.stringify(newCart))
    }
  }
  const handleDecrement = () => {
    if(counter>1){
      setCounter((prev) => prev-1)
      if(items){
        const newCart = [...cartData.cartData]
        newCart.map((item)=>{
          if(items.id===item.id)
          {
            item.counter=item.counter-1
          }
        })
        cartData.setCartData(newCart)
        localStorage.setItem('items',JSON.stringify(newCart))
      }
    }
    else{
      setCounter(counter)
      { del && del(items.id)}
    }
  }
  // setCounter((prev) => prev+1)
  // if(items){
  //   if(items.counter>=1){
  //     const newCart = [...cartData.cartData]
  //     newCart.map((item)=>{
  //       if(items.id===item.id)
  //       {
  //         item.counter=item.counter+1
  //       }
  //     })
  //     cartData.setCartData(newCart)
  //     localStorage.setItem('items',JSON.stringify(newCart))
  //   }
  //   else{
  //     del(items.id)
  //   } 
  return (
    <div className={`w-full flex gap-3 ${test ? 'px-10':''} justify-between text-xl select-none`}>
      <p className='cursor-pointer' onClick={() => handleDecrement()}>-</p>
      <p>{items ? items.counter : counter}</p>
      <p className='cursor-pointer' onClick={() => handelIncriment()}>+</p>
    </div>
  )
}

export default Counter