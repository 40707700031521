import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GoSettings } from "react-icons/go";
import { BsCurrencyDollar, BsHeart, BsHandbag, BsPerson } from "react-icons/bs";
import { BiHeart } from "react-icons/bi";
import { VscAccount } from "react-icons/vsc";
import { AiOutlineShopping } from "react-icons/ai";
import WhiteLogo from "./image/logo.png";
import BlackLogo from "./image/logo1.png";
import { TbCurrencyLari } from "react-icons/tb";
import WomenHover from "./HeaderComponents/womenHover/WomenHover";
import MenHover from "./HeaderComponents/menhover/MenHover";
import ChildrenHover from "./HeaderComponents/childrenHover/ChildrenHover";
import CartShow from "../contextComponents/CartShow";
import CartData from "../contextComponents/CartData";

const Header = () => {
  const [pages, setPages] = useState("Home");
  const [state, setState] = useState();
  const cartIsShow = useContext(CartShow);
  const cartData = useContext(CartData);
  const [countObj, setCountObj] = useState(cartData.cartData.length);
  const [show, setShow] = useState(false);
  const [val, setVal] = useState("Gel");
  window.addEventListener("scroll", function () {
    setState(window.scrollY);
  });
  const { pathname } = useLocation();
  useEffect(() => {
    const newValue = cartData.cartData.length;
    setCountObj(newValue);
  }, [cartData.cartData]);
  return (
    <div
      className={`w-full flex justify-center items-center  fixed z-[5] ${
        pathname === "/" ? `${state && "bg-white duration-500"}` : "bg-white"
      } `}
    >
      <div className="w-[80%]  py-[20px] flex items-center justify-between">
        <Link to="/">
          <div className="w-36">
            <img
              src={
                pathname === "/" ? (state ? BlackLogo : WhiteLogo) : BlackLogo
              }
              alt=""
            />
          </div>
        </Link>
        <nav
          className={` max-xl:hidden flex gap-10 mt-[]  text-sm ${
            pathname === "/"
              ? `${
                  state ? "text-black duration-500" : "text-white duration-500"
                }`
              : `text-black duration-500`
          }`}
        >
          <Link to="/">
            <h1
              onClick={() => setPages("Home")}
              className={`${
                pathname === "/"
                  ? `${
                      pages === "Home"
                        ? `${
                            state
                              ? "border-b-2 border-slate-300"
                              : "border-b-2 border-white"
                          }`
                        : `hover:border-b-2 ${
                            state ? "hover:slate-200" : "hover:border-white"
                          } duration-100`
                    }`
                  : `${
                      pages === "Home"
                        ? "border-b-2 border-[#fe9a7e]"
                        : "hover:border-b-2 hover:border-slate-200"
                    }`
              }`}
            >
              მთავარი
            </h1>
          </Link>
          <div className="flex flex-col group">
            <h1
              onClick={() => setPages("Settings")}
              className={`${
                pathname === "/"
                  ? `${
                      pages === "Settings"
                        ? `${
                            state
                              ? "border-b-2 border-slate-300"
                              : "border-b-2 border-white"
                          }`
                        : `hover:border-b-2 ${
                            state ? "hover:slate-200" : "hover:border-white"
                          } duration-100`
                    }`
                  : `${
                      pages === "Settings"
                        ? "border-b-2 border-[#fe9a7e]"
                        : "hover:border-b-2 hover:border-slate-200"
                    }`
              }`}
            >
              ახალი
            </h1>
            <div className="bg-white w-full overflow-hidden h-0 absolute left-0 mt-14 rounded-lg opacity-0 group-hover:opacity-100 group-hover:h-screen duration-500"></div>
          </div>
          <h1
            onClick={() => setPages("Catalog")}
            className={`${
              pathname === "/"
                ? `${
                    pages === "Catalog"
                      ? `${
                          state
                            ? "border-b-2 border-slate-300"
                            : "border-b-2 border-white"
                        }`
                      : `hover:border-b-2 ${
                          state ? "hover:slate-200" : "hover:border-white"
                        } duration-100`
                  }`
                : `${
                    pages === "Catalog"
                      ? "border-b-2 border-[#fe9a7e]"
                      : "hover:border-b-2 hover:border-slate-200"
                  }`
            }`}
          >
            კატალოგი
          </h1>
          <div className="flex flex-col group">
            <h1
              onClick={() => setPages("Women")}
              className={`${
                pathname === "/"
                  ? `${
                      pages === "Women"
                        ? `${
                            state
                              ? "border-b-2 border-slate-300"
                              : "border-b-2 border-white"
                          }`
                        : `hover:border-b-2 ${
                            state ? "hover:slate-200" : "hover:border-white"
                          } duration-100`
                    }`
                  : `${
                      pages === "Women"
                        ? "border-b-2 border-[#fe9a7e]"
                        : "hover:border-b-2 hover:border-slate-200"
                    }`
              }`}
            >
              ქალი
            </h1>
            <WomenHover />
          </div>
          <div className="flex flex-col group">
            <h1
              onClick={() => setPages("Pages")}
              className={`${
                pathname === "/"
                  ? `${
                      pages === "Pages"
                        ? `${
                            state
                              ? "border-b-2 border-slate-300"
                              : "border-b-2 border-white"
                          }`
                        : `hover:border-b-2 ${
                            state ? "hover:slate-200" : "hover:border-white"
                          } duration-100`
                    }`
                  : `${
                      pages === "Pages"
                        ? "border-b-2 border-[#fe9a7e]"
                        : "hover:border-b-2 hover:border-slate-200"
                    }`
              }`}
            >
              ფასდაკლება
            </h1>
            <div className="bg-white w-[300px] overflow-hidden h-0 absolute mt-14 rounded-lg opacity-0 group-hover:opacity-100 group-hover:h-[450px] duration-500"></div>
          </div>
          <div className="flex flex-col group">
            <h1
              onClick={() => setPages("Men")}
              className={`${
                pathname === "/"
                  ? `${
                      pages === "Men"
                        ? `${
                            state
                              ? "border-b-2 border-slate-300"
                              : "border-b-2 border-white"
                          }`
                        : `hover:border-b-2 ${
                            state ? "hover:slate-200" : "hover:border-white"
                          } duration-100`
                    }`
                  : `${
                      pages === "Men"
                        ? "border-b-2 border-[#fe9a7e]"
                        : "hover:border-b-2 hover:border-slate-200"
                    }`
              }`}
            >
              მამაკაცი
            </h1>
            <MenHover />
          </div>
          <div className="flex flex-col group">
            <h1
              onClick={() => setPages("Buy Lumia")}
              className={`${
                pathname === "/"
                  ? `${
                      pages === "Buy Lumia"
                        ? `${
                            state
                              ? "border-b-2 border-slate-300"
                              : "border-b-2 border-white"
                          }`
                        : `hover:border-b-2 ${
                            state ? "hover:slate-200" : "hover:border-white"
                          } duration-100`
                    }`
                  : `${
                      pages === "Buy Lumia"
                        ? "border-b-2 border-[#fe9a7e]"
                        : "hover:border-b-2 hover:border-slate-200"
                    }`
              }`}
            >
              ბავშვი
            </h1>
            <ChildrenHover />
          </div>
        </nav>
        <div className="flex gap-3 items-center text-base h-full ">
          {/* <div className='w-6 h-6 flex justify-center items-center '>
          <GoSettings className={`w-full h-full ${pathname === '/' ? `${state ? 'text-black' : 'text-white'}` : `text-black`} hover:text-yellow-400`}/>
          </div> */}
          <div
            className={`mt-[5xp] w-6 h-6 p-[3px] flex flex-col justify-center items-center rounded-full border ${
              state ? "border-gray-800" : "border-white"
            } relative ${pathname !== "/" && 'border-gray-800' }`}
            onClick={() => setShow((prev) => !prev)}
          >
            {val === "Gel" ? (
              <TbCurrencyLari
                className={`w-full h-full ${
                  pathname === "/"
                    ? `${state ? "text-gray-800" : "text-white"}`
                    : `text-gray-800`
                } hover:text-yellow-400 `}
              />
            ) : (
              <BsCurrencyDollar
                className={`w-full h-full ${
                  pathname === "/"
                    ? `${state ? "text-gray-800" : "text-white"}`
                    : `text-gray-800`
                } hover:text-yellow-400`}
              />
            )}
            {show && (
              <div className="absolute flex flex-col justify-between py-[4px] px-[4px] items-center w-8 h-20 bg-slate-500 mt-[350%] rounded-2xl">
                <div
                  className="w-6 h-6 p-[3px] flex flex-col justify-center items-center rounded-full border-spacing-[50px] border border-white relative"
                  onClick={() => setVal("Gel")}
                >
                  <TbCurrencyLari
                    className={`w-full h-full ${
                      pathname === "/"
                        ? `${state ? "text-gray-800" : "text-white"}`
                        : `text-gray-800`
                    } hover:text-yellow-400`}
                  />
                </div>
                <div
                  className="w-6 h-6 p-[3px] flex flex-col justify-center items-center rounded-full border border-white relative"
                  onClick={() => setVal("Dol")}
                >
                  <BsCurrencyDollar
                    className={`w-full h-full ${
                      pathname === "/"
                        ? `${state ? "text-gray-800" : "text-white"}`
                        : `text-gray-800`
                    } hover:text-yellow-400`}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-6 h-6 flex justify-center items-center">
            <BsPerson
              className={`w-full h-full  ${
                pathname === "/"
                  ? `${state ? "text-gray-800" : "text-white"}`
                  : `text-gray-800`
              } hover:text-yellow-400`}
            />
          </div>
          <div className="w-6 h-6 p-[2px] flex justify-center items-center">
            <BsHeart
              className={`w-full h-full ${
                pathname === "/"
                  ? `${state ? "text-gray-800" : "text-white"}`
                  : `text-gray-800`
              } hover:text-yellow-400 `}
            />
          </div>
          <div
            onClick={() => cartIsShow.setCartIsShow(true)}
            className="w-6 h-6 p-[2px] relative flex justify-center flex-col items-center"
          >
            <BsHandbag
              className={`w-full h-full ${
                pathname === "/"
                  ? `${state ? "text-gray-800" : "text-white"}`
                  : `text-gray-800`
              } hover:text-yellow-400`}
            />
            <div className="w-[55%] h-[55%] -top-1 left-[22px]  absolute rounded-full bg-slate-500">
              <div className="w-full h-full flex justify-center items-center">
                <p className="text-[9px] text-white text-center">{countObj}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
