import React from 'react'
import {IoMdArrowDropright} from 'react-icons/io'

const WomenHoverShoes = () => {
  return (
    <div className="w-[80%] flex gap-10 bg-white bg-opacity-100 ">
      <div className='w-[15%] ml-[30px]  flex flex-col gap-3 text-black py-3'>
        <h1 className='hover:text-blue-600'>ფეხსაცმელი</h1>
        <div className='flex flex-col gap-[2px] '>
          <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='flex hover:text-blue-600 items-center  gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შორტი</p>
          </div>    
          <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>მაისური</p>
          </div>    
          <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>პერანგი / ბლუზი</p>
          </div>
          <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>ქურთუკი</p>
          </div>    
          <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>ჟილეტი</p>
          </div>    
          <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>პიჯაკი</p>
          </div>    
        </div>
      </div>
      <div className='w-[15%] flex flex-col gap-3'>
        <div className='w-full flex flex-col gap-3 text-black py-3'>
          <h1 className='hover:text-blue-600'>ფეხსაცმელი</h1>
          <div className='flex flex-col gap-[2px] '>
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright />
              <p className='text-sm'>ჯინსი</p>
            </div>    
            <div className='flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>შარვალი</p>
            </div>    
            <div className='flex hover:text-blue-600 items-center  gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>შორტი</p>
            </div>    
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>მაისური</p>
            </div>      
          </div>
        </div>
      </div>
      <div className='w-[15%] flex flex-col gap-3 text-black py-3'>
          <h1 className='hover:text-blue-600'>ფეხსაცმელი</h1>
          <div className='flex flex-col gap-[2px] '>
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright />
              <p className='text-sm'>ჯინსი</p>
            </div>    
            <div className='flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>შარვალი</p>
            </div>    
            <div className='flex hover:text-blue-600 items-center  gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>შორტი</p>
            </div>    
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>მაისური</p>
            </div>      
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>მაისური</p>
            </div>      
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>მაისური</p>
            </div>      
            <div className='flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
              <IoMdArrowDropright/>
              <p className='text-sm'>მაისური</p>
            </div>      
          </div>
        </div>
    </div>
  )
}

export default WomenHoverShoes