import React, { useState } from 'react'
import {BsArrowRightShort} from 'react-icons/bs'
import WomenHoverClothers from './WomenHoverClothers'
import WomenHoverShoes from './WomenHoverShoes'
import WomenHoverAccessories from './WomenHoverAccessories'
import WomenHoverPremium from './WomenHoverPremium'

const WomenHover = () => {
  const [typeCloth,setTypeCloth] = useState('clother')
  return (
    <div className="w-full overflow-hidden h-0 bg-white bg-opacity-80 absolute mt-[53px] left-0 rounded-lg opacity-0 group-hover:opacity-100 group-hover:h-[500px] duration-500"> 
      <div className='w-full h-full flex gap-5'>
        <div className='flex flex-col w-[17%] h-full text-black bg-opacity-80'>
          <div
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='clother' ? 'text-blue-600':''} cursor-pointer`}
            onMouseOver={() => setTypeCloth('clother')}
            >
            <h1
              className='text-sm pl-2'>ქალის ტანსაცმელი</h1>
            <BsArrowRightShort/>
          </div>
          <div 
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='shoes' ? 'text-blue-600':''} cursor-pointer`}
            onMouseOver={() => setTypeCloth('shoes')}
            >
            <h1
              className='text-sm pl-2'>ქალის ფეხსაცმელი</h1>
            <BsArrowRightShort/>
          </div>
          <div 
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='accessories' ? 'text-blue-600':''} cursor-pointer`}
            onMouseOver={() => setTypeCloth('accessories')}
            >
            <h1 className='text-sm pl-2'>ქალის აქსესუარები</h1>
            <BsArrowRightShort/>
          </div>
          <div 
            className={`w-full h-auto border-b-2 border-slate-300 flex items-center justify-between p-3 hover:text-blue-600 ${typeCloth==='premium' ? 'text-blue-600':''}  cursor-pointer`}
            onMouseOver={() => setTypeCloth('premium')}
            >
            <h1 className='text-sm pl-2'>ქალის(პრემიუმი)</h1>
            <BsArrowRightShort/>
          </div>
          
        </div>
        {typeCloth === 'clother' && <WomenHoverClothers/>}
        {typeCloth === 'shoes' && <WomenHoverShoes/>} 
        {typeCloth === 'accessories' && <WomenHoverAccessories/>} 
        {typeCloth === 'premium' && <WomenHoverPremium/>} 
      </div>
    </div>
  )
}

export default WomenHover
