import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import '../../../style/main.css'
import ClotherSliderComponent from "./ClotherSliderComponent";

// import { BootsComponent } from "./BootsComponent";

const ClotherSlider = () => {
  const [data,setData] = useState([
    {
      id: 1,
      title: "პერანგი",
      name: "ქალის პერანგი",
      price: '37',
      category:'',
      size: ['XS','S','M','L'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_931864c9-79ea-488e-8f87-eae026fd2ad0.png?v=1676889115&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_ff9eda00-d87a-4d87-8679-c2c59b6a07fb.png?v=1676889116&width=357'
    },
    {
      id: 2,
      title: "ჩანთა",
      name: "ქალის ჩანთა",
      price: '188',
      category:'',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_b3b71733-712b-484c-80ac-e4f35d84cb79.png?v=1675501308&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_b3b71733-712b-484c-80ac-e4f35d84cb79.png?v=1675501308&width=357'
    },
    {
      id: 3,
      title: "პიჯაკი",
      name: "ქალის პიჯაკი",
      price: '69',
      category:'',
      size: ['S','M','L','XL'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_55dc9372-ef76-44bb-b746-37b541073b5f.png?v=1676888394&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_d29efd9d-d9e8-4adb-8d45-de4d198f9135.png?v=1676888394&width=357'
    },
    {
      id: 4,
      title: "ბოტასი",
      name: "ქალის ბოტასი",
      price: '47',
      category:'boots',
      size: ['6','6.5','7','7.5','8','8.5','9'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_d3b77bb9-cc5a-415a-a6e5-6dfd36a0d753.png?v=1676383030&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2d3629e1-0257-47d9-bf8d-b2464b4ddc07.png?v=1676383031&width=357'
    },
    {
      id: 5,
      title: "პიჯაკი",
      name: "ქალის პიჯაკი",
      price: '26',
      category:'',
      size: ['S','M','L','XL'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_20ba7f09-87c6-488d-8f62-7589a4c31626.png?v=1676888681&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2adf802f-6e2c-428b-8448-0100eef4de68.png?v=1676888681&width=357'
    },
  ])
  return (
    <div className="w-full h-auto px-[13%]">
      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="bootsSwiper w-full h-[600px]"
      >
        {
          data.map((item) => (
            <SwiperSlide 
              key={item.id}
              className="w-[23%]">
              <ClotherSliderComponent item={item}/>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  )
}

export default ClotherSlider