import { useState } from "react";
import Counter from "../Counter";
import Select from "./Select";
import {TbCurrencyLari} from 'react-icons/tb';
import {MdStarRate} from 'react-icons/md'
import {BsFillEyeFill} from 'react-icons/bs'

export const SaleSlider1 = ({ item, setPopUpShow, setSelectedItem}) => {
  const [colorValue, setColorValue] = useState("blue");
  const [sizeValue, setSizeValue] = useState("");
  const [imgChange, setImgChange] = useState(item.img);
  const dataColor=['blue','red', 'yellow','grey']
  const dataSize=['xs','s', 'm','l']
  return (
    <div
      onMouseOver={() => setImgChange(item.img1)}
      onMouseOut={() => setImgChange(item.img)}
      className="w-full  h-[90%] bg-white  flex-col rounded-lg flex 
      pt-5 gap-3 justify-end group duration-300 border border-slate-200"
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-center p-3">
          <h1 className="uppercase text-base ">{item.title}</h1>
          <div className="flex gap-1 items-center ">
            <div className="flex items-center">
              <div className='w-5 h-5 flex justify-center items-center font-thin'>
                <TbCurrencyLari className={`w-[90%] h-[90%] text-black  `}/>
              </div>
              <h1 className=" text-base">{item.price}</h1>
            </div>
            <div className="flex items-end mt-[5px] line-through">     
              <h1 className=" text-sm text-slate-500">{item.oldPrice}</h1>
            </div>
          </div>
        </div>
        <img className="w-full h-[300px] " src={imgChange} alt="" />
      </div>
      <div className='flex flex-col gap-3 items-center mb-4'>
        <h1 className="text-center capitalize text-sm  ">
          {item.name}
        </h1>
        <button 
          // onClick={() => {setPopUpShow(true); setSelectedItem(item)}}
          className='flex duration-300 gap-2 justify-center w-[50%] items-center bg-blue-700 text-white rounded-full py-[6px]'>
            <BsFillEyeFill 
              className='w-[20px] h-[22px] cursor-pointer hover:scale-125 duration-300'/>
              <p>ნახვა</p>
        </button>
      </div>
    </div>
  );
};
