import { useState } from "react";
import Counter from '../../homePage/Counter'
import {AiTwotoneHeart,AiFillEye} from 'react-icons/ai'
import {TbCurrencyLari} from 'react-icons/tb'
import {MdStarRate} from 'react-icons/md'

const BestSellers3 = ({ item }) => {
  const [ramValue, setRamValue] = useState("8Gb");
  const [ssdValue, setSsdValue] = useState("");
  const [imgChange, setImgChange] = useState(item.src);
  const [defCounter, setDefCounter] = useState(1);

  return (
    <div
      onMouseOver={() => setImgChange(item.src1)}
      onMouseOut={() => setImgChange(item.src)}
      className="w-full h-[90%] bg-white shadow-2xl flex-col rounded-lg flex pt-5 gap-3 justify-end group duration-300"
    >
      <div className="flex justify-end">
        <img className="w-full h-[300px] rounded-md" src={imgChange} alt="" />
        <div className='flex flex-col absolute mt-7 mr-3'>
          <AiTwotoneHeart className='text-slate-500  w-[30px] h-[32px] cursor-pointer  hover:scale-125 duration-300 '/>
          <AiFillEye className='text-slate-500  w-[30px] h-0 group-hover:h-[32px] cursor-pointer hover:scale-125 duration-300'/>
        </div>
      </div>
      <h1 className="text-center capitalize text-sm font-semibold  ">{item.name}</h1>
      <h1 className="text-center capitalize text-sm font-medium mb-3 ">
        {item.name1}
      </h1>
      <div className='flex gap-1 justify-center mb-3'>
        <MdStarRate/>
        <MdStarRate/>
        <MdStarRate/>
        <MdStarRate/>
        <MdStarRate/>
      </div>
      <div className="flex items-center justify-center pb-5">
        <div className='w-5 h-5 flex justify-center items-center font-thin'>
          <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
        </div>
        <h1 className="text-lg">{item.price}</h1>
      </div>
      <div className="w-full auto py-5 gap-3 rounded-lg shadow-2xl border border-slate-200 bg-white absolute mb-0  flex-col items-center flex opacity-0 group-hover:opacity-100 duration-500">
        <h1 className="capitalize text-sm font-semibold mt-3">{item.name}</h1>
        <div className="flex items-center justify-center  mr-5">
          <div className='w-5 h-5 flex justify-center items-center font-thin'>
            <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
          </div>
          <h1 className="text-xl text-slate-700">{item.price}</h1>
        </div>
        <div className="flex flex-col gap-3">
          <Counter def={defCounter} />
          <div className="w-full h-auto flex items-center justify-center px-[10%]">
            <button className="bg-blue-600 text-white rounded-full w-full text-center py-1">
              შეკვეთა
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BestSellers3