import React from 'react'
import {TbCurrencyLari} from 'react-icons/tb'
import {MdStarRate} from 'react-icons/md'
import { useState } from 'react'

const FeaturedItemsComponent = ({item}) => {
  const [imageChange,setImageChange] = useState(item.src1)
  return (
    <div className='w-[23%] h-auto py-5 flex gap-2 '>
      <img 
        onMouseOver={() => setImageChange(item.src2)}
        onMouseOut={() => setImageChange(item.src1)}
        className='w-[125px] h-[149px]' src={imageChange} alt=''/>
      <div className='flex flex-col h-auto gap-2  justify-center'>
        <h1 className='cursor-pointer hover hover:text-[#fe9a7e]'>{item.name}</h1>
        <div className="flex items-center">
          <div className='w-5 h-5 flex justify-center items-center font-thin'>
            <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
          </div>
          <h1 className="font-bold text-lg">{item.price}</h1>
        </div>
        <div className='flex gap-[2px] justify-center mb-4'>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
        </div>
      </div>
    </div>
  )
}

export default FeaturedItemsComponent