import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import '../../style/main.css'
import BestSellers  from "./bestSellersComponents/BestSellers";
import BestSellers2 from "./bestSellersComponents/BestSellers2";
import BestSellers3 from "./bestSellersComponents/BestSellers3";
import BestSellers4 from "./bestSellersComponents/BestSellers4";


const BestSellersSlider = () => {
  const [data,setData] = useState([
    {
      id: 1,
      name: "ASUS",
      name1: "ლეპტოპი",
      price: '47',
      color:[
        {
          id:1,
          name:'8Gb',
          size:['128Mb','256Mb' ,'512Mb']
        },
        {
          id:2,
          name:'16Gb',
          size:['256Mb']
        }
      ],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_a6f8ed3c-4280-4d3b-95bd-212a1b66c08d.png?v=1676472643&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_b4e2602b-9cab-493a-87f6-42be53b7b188.png?v=1676472644&width=357'
    },
    {
      id: 2,
      name: "SAMSUNG",
      name1: "ტელეფონი",
      price: '47',
      color:[
        {
          id:1,
          name:'8Gb',
          size:['256Mb','15Mb' ,'xl']
        },
        {
          id:2,
          name:'16Gb',
          size:['256Mb']
        }
      ],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_3817f09a-3898-48a6-a09d-f7c9cd9bd58e.png?v=1676535318&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_8b048da2-d0b2-4a7c-be5d-d8d5542c892c.png?v=1676535318&width=357'
    },
    {
      id: 3,
      name: "SONY",
      name1: "ყურსასმენი",
      price: '47',
      color:[
        {
          id:1,
          name:'8Gb',
          size:['256Mb','15Mb' ,'xl']
        },
        {
          id:2,
          name:'16Gb',
          size:['256Mb']
        }
      ],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_a98cebae-3398-420c-b9fd-c542e2ac8b22.png?v=1676470244&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3dffbffc-0a6f-4e71-b960-f58894174743.png?v=1676470244&width=357'
    },
    {
      id: 4,
      name: "ONE PLUS",
      name1: "ყურსასმენი",
      price: '47',
      color:[
        {
          id:1,
          name:'8Gb',
          size:['256Mb','15Mb' ,'xl']
        },
        {
          id:2,
          name:'16Gb',
          size:['256Mb']
        }
      ],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_20aa8a92-f074-4ce5-b98b-fda7ac75471e.png?v=1676471360&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_20aa8a92-f074-4ce5-b98b-fda7ac75471e.png?v=1676471360&width=357'
    },
    {
      id: 5,
      name: "SAMSUNG",
      name1: "ტელევიზორი",
      price: '47',
      size:['43"', '45"', '48"','50"'],
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_3673db6e-eaaa-469e-a7bf-afeb4bc2c8a9.png?v=1676467905&width=357',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_edd9ebcb-22bf-4739-81fe-e92d2eed2a76.png?v=1676467904&width=357'
    }
  ])
  return (
    <div className="w-full h-auto ">
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="clotherSwiper w-full h-[510px]"
      >
        {
          data.map((item) => {
            if(item.id === 1){
              return(
              <SwiperSlide 
                key={item.id}
                className="w-[23%]">
                <BestSellers item={item}/>
              </SwiperSlide>
              )
            }
            if(item.id === 2){
              return(
              <SwiperSlide 
                key={item.id}
                className="w-[23%]">
                <BestSellers2 item={item}/>
              </SwiperSlide>
              )
            }
            if(item.id === 3){
              return(
              <SwiperSlide 
                key={item.id}
                className="w-[23%]">
                <BestSellers3 item={item}/>
              </SwiperSlide>
              )
            }
            if(item.id === 4){
              return(
              <SwiperSlide 
                key={item.id}
                className="w-[23%]">
                <BestSellers3 item={item}/>
              </SwiperSlide>
              )
            }
            if(item.id === 5){
              return(
              <SwiperSlide 
                key={item.id}
                className="w-[23%]">
                <BestSellers4 item={item}/>
              </SwiperSlide>
              )
            }
          }
          )
        }
      </Swiper>
    </div>
  )
}

export default BestSellersSlider