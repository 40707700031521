import React, { useState } from 'react'
import WhatPeopleSayComponent from './WhatPeopleSayComponent'
import SlideFromBottom from '../Animations/SlideFromBottom'
const WhatPeopleSay = () => {
  const [data,setData] = useState([
    {
      id:1,
      name:'assus',
      type:'ლეპტოპი',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_a6f8ed3c-4280-4d3b-95bd-212a1b66c08d.png?v=1676472643&width=180',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_b4e2602b-9cab-493a-87f6-42be53b7b188.png?v=1676472644&width=180',
      name1:'პორტატული ლეპტოპი',
      description:'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. '+ 
        'შემთხვევითად გენერირებული ტექსტი ეხმარება'
    },
    {
      id:2,
      name:'samsung',
      type:'ტელეფონი',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_3817f09a-3898-48a6-a09d-f7c9cd9bd58e.png?v=1676535318&width=180',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_8b048da2-d0b2-4a7c-be5d-d8d5542c892c.png?v=1676535318&width=180',
      name1:'პორტატული ტელეფონი',
      description:'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. '+ 
        'შემთხვევითად გენერირებული ტექსტი ეხმარება'
    },
    {
      id:3,
      name:'Shannon Nelson',
      type:'ტაბლეტი',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_0b7a6ec9-b36b-43ad-898d-3fe375ac49af.png?v=1676471488&width=180',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e9e5bd2c-5bb4-47cb-a342-76a013b3394d.png?v=1676471487&width=180',
      name1:'პორტატული ტაბლეტი',
      description:'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. '+ 
        'შემთხვევითად გენერირებული ტექსტი ეხმარება'
    },
    {
      id:4,
      name:'samsung',
      type:'ტელეფონი',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_3817f09a-3898-48a6-a09d-f7c9cd9bd58e.png?v=1676535318&width=180',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_8b048da2-d0b2-4a7c-be5d-d8d5542c892c.png?v=1676535318&width=180',
      name1:'პორტატული ტელეფონი',
      description:'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. '+ 
      'შემთხვევითად გენერირებული ტექსტი ეხმარება'
    },
    {
      id:5,
      name:'Shannon Nelson',
      type:'ტაბლეტი',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_0b7a6ec9-b36b-43ad-898d-3fe375ac49af.png?v=1676471488&width=180',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e9e5bd2c-5bb4-47cb-a342-76a013b3394d.png?v=1676471487&width=180',
      name1:'პორტატული ტაბლეტი',
      description:'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. '+ 
        'შემთხვევითად გენერირებული ტექსტი ეხმარება'
    },
    {
      id:6,
      name:'Shannon Nelson',
      type:'ლეპტოპი',
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_a6f8ed3c-4280-4d3b-95bd-212a1b66c08d.png?v=1676472643&width=180',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_b4e2602b-9cab-493a-87f6-42be53b7b188.png?v=1676472644&width=180',
      name1:'პორტატული ლეპტოპი',
      description:'შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. '+ 
        'შემთხვევითად გენერირებული ტექსტი ეხმარება'
    },
  ])
  return (
    <div className='flex flex-wrap w-full h-auto py-10 gap-5'>
      {
        data.map((item) => (
          <WhatPeopleSayComponent key={item.id} item={item}/>
        ))
      }
    </div>
  )
}

export default WhatPeopleSay