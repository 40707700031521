import { useContext, useState } from "react";
import {TbCurrencyLari} from 'react-icons/tb'
import CartShow from "../../../contextComponents/CartShow";
import CartData from "../../../contextComponents/CartData";
import HoverStyle1 from "./HoverStyle1";
import HoverStyle2 from "./HoverStyle2";
export const CardMain = ({ item, setPopUpShow, setSelectedItem }) => {
  const itemStyle=item.style
  const [colorValue, setColorValue] = useState("blue");
  const [sizeValue, setSizeValue] = useState("");
  const [imgChange, setImgChange] = useState(item.img);
  const cartShow = useContext(CartShow)
  const cartData = useContext(CartData)
  const [counterValue1, setCounterValue1] = useState(1)
  const [def,setDef] = useState(1)
  const addCart = (items) =>{
    if(counterValue1>0){
      const filtered=cartData.cartData.find((item) => item.id===items.id)
      if(filtered)
      {
        // if((colorValue !== filtered.colorValue) && (sizeValue !== filtered.sizeValue)){
        //   cartData.cartData.map((item)=>{
        //     if(items.id===item.id)
        //     {
        //       item.counter=counterValue1+item.counter
        //     }
        //   })
        //     localStorage.setItem('items',JSON.stringify(cartData.cartData))
        // }
        // else{
        //   const newCart=[{counter:counterValue1, colorValue:colorValue ,sizeValue:sizeValue, ...items},...cartData.cartData]
        //   cartData.setCartData(newCart)
        //   localStorage.setItem('items',JSON.stringify(newCart))
        // }
        cartData.cartData.map((item)=>{
          if(items.id===item.id)
          {
            item.counter=counterValue1+item.counter
          }
        })
          localStorage.setItem('items',JSON.stringify(cartData.cartData))
      }
      else
      {
        const newCart=[{counter:counterValue1, colorValue:colorValue ,sizeValue:sizeValue, ...items},...cartData.cartData]
        cartData.setCartData(newCart)
        localStorage.setItem('items',JSON.stringify(newCart))
      }
    }
  }

  return (
    <div
      className="w-full h-[450px] bg-white  flex-col rounded-lg flex 
      pt-5 gap-2 justify-end group relative duration-300 border border-slate-200"
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-start p-3">
          <h1 className="uppercase text-base">{item.title}</h1>
          <div className="flex items-center">
            <div className='w-5 h-5 flex justify-center items-center font-thin'>
              <TbCurrencyLari className={`w-[90%] h-[90%] text-black  `}/>
            </div>
            <h1 className=" text-base">{item.price}</h1>
          </div>
        </div>
        <img className="w-full h-[300px] " src={imgChange} alt="" />
      </div>
      <div className={`flex flex-col ${itemStyle === '2' ? 'mt-[44px]' : 'gap-3'} ${itemStyle === '1'&&'mt-3'}`}>
        {
          (!(itemStyle === '2')) && 
          <div className="flex gap-1 justify-center">
            {item.color.map((item,index) => (
              <div
                key={index}
                onMouseOver={() => {
                  setColorValue(item.name1);
                  setSizeValue("");
                }}
                onClick={() => {
                  setColorValue(item.name1);
                  setSizeValue("");
                  setImgChange(item.src1)
                }}
                className={`
                  ${itemStyle==='1'?
                    `border-[1px] px-2 flex justify-center items-center rounded-md py-[1px] w-[45px] `
                    :
                    `border-2  ${itemStyle==='4' && `bg-${item.name1}-600`} shadow-md  w-8 flex justify-center rounded-md h-8 `
                    }
                  `}
              >
                {item.src1 && itemStyle==='3'&& <img  className=' ' src={item.src1} alt=''/>}
                {itemStyle === '1' && <p className="text-slate-700 text-[12px]">{item.name1}</p>}
              </div>
            ))}
          </div>
        }
        <h1 className="text-center capitalize text-sm font-semibold mb-6 ">
          {item.name}
        </h1>
      </div>
      {
        (!(item.style === '2')) ?
        <HoverStyle1
        item={item}
        colorValue={colorValue}
        setColorValue={setColorValue}
        setSizeValue={setSizeValue}
        setImgChange={setImgChange}
        sizeValue={sizeValue}
        cartShow={cartShow}
        addCart={addCart}
        setDef={setDef}
        setCounterValue1={setCounterValue1}
        def={def}
        />
        :
        <HoverStyle2
        item={item}
        colorValue={colorValue}
        setColorValue={setColorValue}
        sizeValue={sizeValue}
        setSizeValue={setSizeValue}
        cartShow={cartShow}
        addCart={addCart}
        def={def}
        setDef={setDef}
        setCounterValue1={setCounterValue1}




        />
      }
    </div>
  );
};
