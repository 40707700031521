import React, { useState } from 'react'
import {AiOutlineShopping} from 'react-icons/ai'
import {FiHeart} from 'react-icons/fi'
import {BsSearch} from 'react-icons/bs'
import {RiAccountCircleLine} from 'react-icons/ri'
import FirstSlider from './FirstSlider'
import SpecialOffer from './SpecialOffer'
import BrandSlider from './BrandSlider'
import SlideFromBottom from '../Animations/SlideFromBottom'
import BestSellersSlider from './BestSellersSlider'
import FeaturedItems from './FeaturedItems'
import WhatPeopleSay from './WhatPeopleSay'
import NavigationHead from './NavigationHead'
import ElectronicsHeader from './ElectronicsHeader'
import ElectronicsFooter from './ElectronicsFooter'
const Electronics = () => {
  const [value,setValue] = useState('')
  return (
    <div className="w-full h-auto flex flex-col">
      <ElectronicsHeader/>
      <div className='h-auto px-[12.5%] py-10'>
        <div className='w-full flex justify-between items-center'>
          <div className='w-36 h-auto'>
            <img className='w-full h-full' src={require('../image/logo1.png')} alt=''/>
          </div>
          <div className=' w-[80%] flex justify-end items-center gap-5 '>
            <div className='w-[80%] border-b border-blue-600 py-2 flex justify-between px-[2px] items-center'>
              <input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className='w-full outline-none'
                type='text'
                placeholder='ჩაწერეთ პროდუქტის სახელი'/>
              <div className='w-[20px] h-[20px]'>
                <BsSearch className='w-full h-full'/>
              </div>
            </div>
            <div className='flex gap-3'>
              <div className='w-15 h-9 flex justify-center items-center'>
                <RiAccountCircleLine className='w-full h-full text-slate-500'/>
              </div>
              <div className='w-15 h-9 flex justify-center items-center'>
                <FiHeart className='w-full h-full text-slate-500'/>
              </div>
              <div className='w-15 h-9 flex justify-center items-center'>
                <AiOutlineShopping className='w-full h-full text-slate-500'/>
              </div>
            </div>
          </div>
        </div>
        <SlideFromBottom>
          <div className='w-full flex py-10 gap-7'>
            <NavigationHead/>
            <div className='w-[74%] h-auto '>
              <FirstSlider/>
            </div>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <div className='w-full h-[500px] flex pb-5 gap-7'>
            <div className='w-[33%]'>
              <div className='banner1 rounded-2xl'>
              </div>
            </div>
            <div className='w-[33%] h-full flex flex-col gap-5'>
              <div className='w-full h-[49%]'>
                <div className='banner2 rounded-2xl'>
                </div>
              </div>
              <div className='w-full h-[49%]'>
                <div className='banner3 rounded-2xl'>
                </div>
              </div>
            </div>
            <div className='w-[33%]'>
              <div className='banner4 rounded-2xl'>
              </div>
            </div>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <div className='w-full flex py-10 justify-between'>
            <div className=' w-[32%] h-[360px] rounded-full '>
              <div className='roundedCover1 rounded-full flex justify-center items-center'>
                <div className='w-[60%] h-[60%] rounded-full bg-white flex flex-col justify-center items-center gap-3'>
                  <p>Women</p>
                  <h1 className='text-2xl font-bold'>Smart</h1>
                  <h1 className='text-2xl font-bold'>HDR UHD</h1>
                </div>
              </div>
            </div>
            <div className=' w-[32%] h-[360px] rounded-full '>
              <div className='roundedCover2 rounded-full flex justify-center items-center'>
                <div className='w-[60%] h-[60%] rounded-full bg-white flex flex-col justify-center items-center gap-3'>
                  <p>Women</p>
                  <h1 className='text-2xl font-bold'>Up To</h1>
                  <h1 className='text-2xl font-bold'>50%OFF</h1>
                </div>
              </div>
            </div>
            <div className=' w-[32%] h-[360px] rounded-full '>
              <div className='roundedCover3 rounded-full flex justify-center items-center'>
                <div className='w-[60%] h-[60%] rounded-full bg-white flex flex-col justify-center items-center gap-3'>
                  <p>Women</p>
                  <h1 className='text-2xl font-bold'>Fitness</h1>
                  <h1 className='text-2xl font-bold'>Watch</h1>
                </div>
              </div>
            </div>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <BrandSlider/>  
        </SlideFromBottom>
        <SlideFromBottom>
          <div className='flex flex-col gap-10 pt-10 '>
            <h1 className='pl-5 text-3xl '>საუკეთესო შეთავაზება</h1>
            <BestSellersSlider/>  
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <div className='flex flex-col gap-10 pt-10 '>
            <h1 className='pl-5 text-3xl '>სპეციალური შეთავაზება</h1>
            <SpecialOffer/>
          </div>
        </SlideFromBottom>
        <SlideFromBottom>
          <FeaturedItems/>
        </SlideFromBottom>
        <SlideFromBottom>
          <WhatPeopleSay/>
        </SlideFromBottom>
      </div>
      <SlideFromBottom>
        <ElectronicsFooter/>  
      </SlideFromBottom>
    </div>
  )
}

export default Electronics