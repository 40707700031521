import React, { useState } from 'react'
import FeaturedItemsComponent from './FeaturedItemsComponent'

const FeaturedItems = () => {
  const [data,setData] = useState([
    { 
      id: 1,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_91ea3e12-dae4-41ac-a05e-f1d3ef81f462.png?v=1676473820&width=119',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_19f6031c-ba53-4cd1-bd98-441fac997f77.png?v=1676473820&width=170',
      title:'დინამიკი',
      price:'247'
    },
    { 
      id: 2,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_ffe4aac4-4899-4eea-afdc-b7408167f871.png?v=1676477309&width=170',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_4622b0f0-cb43-48a7-8be6-f0605f99ac18.png?v=1676477309&width=119',
      title:'ტელეფონი',
      price:'247'
    },
    { 
      id: 3,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_91e89363-4780-4430-8204-c26f519db247.png?v=1676474420&width=170',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_7ef8c5f6-b083-4460-a236-0ed438cad4b8.png?v=1676474419&width=170',
      title:'Lighted portable speaker',
      price:'247'
    },
    { 
      id: 4,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_91ea3e12-dae4-41ac-a05e-f1d3ef81f462.png?v=1676473820&width=119',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_19f6031c-ba53-4cd1-bd98-441fac997f77.png?v=1676473820&width=170',
      title:'დინამიკი',
      price:'247'
    },
    { 
      id: 5,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_ffe4aac4-4899-4eea-afdc-b7408167f871.png?v=1676477309&width=170',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_4622b0f0-cb43-48a7-8be6-f0605f99ac18.png?v=1676477309&width=119',
      title:'ტელეფონი',
      price:'247'
    },
    { 
      id: 6,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_91ea3e12-dae4-41ac-a05e-f1d3ef81f462.png?v=1676473820&width=119',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_19f6031c-ba53-4cd1-bd98-441fac997f77.png?v=1676473820&width=170',
      title:'დინამიკი',
      price:'247'
    },
    { 
      id: 7,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_91ea3e12-dae4-41ac-a05e-f1d3ef81f462.png?v=1676473820&width=119',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_19f6031c-ba53-4cd1-bd98-441fac997f77.png?v=1676473820&width=170',
      title:'დინამიკი',
      price:'247'
    },
    { 
      id: 8,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_ffe4aac4-4899-4eea-afdc-b7408167f871.png?v=1676477309&width=170',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_4622b0f0-cb43-48a7-8be6-f0605f99ac18.png?v=1676477309&width=119',
      title:'ტელეფონი',
      price:'247'
    },
  ])
  return (
    <div className='flex w-full h-auto py-10 gap-[5%]'>
      <div className='w-[35%] h-full'>
        <img className='rounded-xl w-full h-full'src='https://cdn.shopify.com/s/files/1/1323/8419/files/banner-05_a21fd9b2-5b0f-463e-ace5-afc62bcba207.png?v=1668932199&width=369' alt=''/>
      </div>
      <div className='w-[60%] h-auto bg-white flex flex-col  gap-3'>
        <div className='flex justify-between '>
          <h1 className='text-2xl'>გამორჩეული ნივთები</h1>
          <h1 className=' text-blue-600'>ყველას ნახვა</h1>
        </div>
        <div className='flex flex-wrap gap-[2%] px-2'>
          {
            data.map((item) => (
              <FeaturedItemsComponent key={item.id} item={item}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default FeaturedItems