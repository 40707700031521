import { useState } from "react";
import Counter from '../../homePage/Counter'
import {AiTwotoneHeart,AiFillEye} from 'react-icons/ai'
import {TbCurrencyLari} from 'react-icons/tb'
import {MdStarRate} from 'react-icons/md'

const BestSellers = ({ item }) => {
  const [ramValue, setRamValue] = useState("8Gb");
  const [ssdValue, setSsdValue] = useState("");
  const [imgChange, setImgChange] = useState(item.src);
  const [defCounter, setDefCounter] = useState(1);

  return (
    <div
      onMouseOver={() => setImgChange(item.src1)}
      onMouseOut={() => setImgChange(item.src)}
      className="w-full h-[90%] bg-white shadow-2xl flex-col rounded-lg flex pt-5 gap-3 justify-end group duration-300"
    >
      <div className="flex justify-end">
        <img className="w-full h-[300px] rounded-md" src={imgChange} alt="" />
        <div className='flex flex-col absolute mt-7 mr-3'>
          <AiTwotoneHeart className='text-slate-500  w-[30px] h-[32px] cursor-pointer  hover:scale-125 duration-300 '/>
          <AiFillEye className='text-slate-500  w-[30px] h-0 group-hover:h-[32px] cursor-pointer hover:scale-125 duration-300'/>
        </div>
      </div>
      <h1 className="text-center capitalize text-sm font-semibold  ">{item.name}</h1>
      <h1 className="text-center capitalize text-sm font-medium mb-3 ">
        {item.name1}
      </h1>
      <div className='flex gap-1 justify-center mb-3'>
        <MdStarRate/>
        <MdStarRate/>
        <MdStarRate/>
        <MdStarRate/>
        <MdStarRate/>
      </div>
      <div className="flex items-center justify-center pb-5">
        <div className='w-5 h-5 flex justify-center items-center font-thin'>
          <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
        </div>
        <h1 className="text-lg">{item.price}</h1>
      </div>
      <div className="w-full h-auto gap-3 pb-5 rounded-lg shadow-2xl bg-white absolute mb-0 border border-slate-200  flex-col items-center flex opacity-0 group-hover:opacity-100 duration-500">
        <h1 className="capitalize text-sm font-semibold mt-3">{item.name}</h1>
        <div className="flex flex-col">
          <h1 className="text-center text-xs mb-[1px]">RAM: {ramValue}</h1>
          <div className="flex gap-1">
            {item.color.map((item,index) => (
              <button
                key={index}
                onMouseOver={() => {
                  setRamValue(item.name);
                  setSsdValue("");
                }}
                onClick={() => {
                  setRamValue(item.name);
                  setSsdValue("");
                }}
                className={`border-2 text-xs w-12 h-10${
                  ramValue === item.name
                    ? "border-blue-500"
                    : "border-slate-500"
                } rounded-xl`}
              >
                <p className="text-slate-500 text-center">{item.name}</p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col">
          <h1 className="text-center text-xs mb-[1px]">SSD:{ssdValue}</h1>
          <div className="flex gap-1">
            {item.color
              .filter((item) => item.name === ramValue)[0]
              .size.map((item,index) => (
                <button
                  key={index}
                  onClick={() => {
                    setSsdValue(item);
                  }}
                  className={`border-[2px] text-xs w-14 py-[2px] rounded-xl ${
                    ssdValue === item
                      ? "border-blue-500"
                      : "border-slate-500"
                  }`}
                >
                  <p className="text-center text-slate-500">{item}</p>
                </button>
              ))}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Counter def={defCounter} />
          <div className="w-full h-auto flex items-center justify-center px-[10%]">
            <button className="bg-blue-600 rounded-full w-full text-center text-white py-1">
              შეკვეთა
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BestSellers