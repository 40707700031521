import React from 'react'
import { useState } from 'react'
import {AiFillEye,AiTwotoneHeart} from 'react-icons/ai'
import {MdStarRate} from 'react-icons/md'
import {TbCurrencyLari} from 'react-icons/tb'
import SlideFromBottom from '../Animations/SlideFromBottom'
const SpecialOfferComponent = ({item}) => {
  const [change,setChange] = useState(item.img)
  return (
    <SlideFromBottom>
      <div 
          onMouseOver={() => setChange(item.img1)}
          onMouseOut={() => setChange(item.img)}
        className='w-full h-[400px] p-[30px] group  flex flex-col gap-10 white border shadow-inner rounded-xl'>
        <div className='w-full flex justify-between'>
          <h1 className='uppercase text-xl'>{item.name}</h1>
          <div className='flex text-slate-300 gap-3'>
            <AiTwotoneHeart className='w-[25px] h-[28px] cursor-pointer  hover:scale-125 duration-300 '/>
            <AiFillEye className='w-[25px] h-[28px] cursor-pointer hover:scale-125 duration-300'/>
          </div>
        </div>
        <div className='w-full flex gap-7'>
          <img className={`w-[45%] h-[200px] rounded-xl `}
          src={change} 
          alt=''/>
          <div className='flex flex-col gap-7'>
            <div className='flex flex-col gap-3'>
              <h1 className=''>{item.name1}</h1>
              <p className='text-sm text-slate-500'>
                {item.description}
              </p>
            </div>
            <div className='flex flex-col gap-2'>
            <div className="flex items-center">
              <div className='w-5 h-5 flex justify-center items-center font-thin'>
                <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
              </div>
              <h1 className="font-bold text-lg">{item.price}</h1>
            </div>
              <p className="flex group-hover:hidden">
                <MdStarRate/>
                <MdStarRate/>
                <MdStarRate/>
                <MdStarRate/>
                <MdStarRate/>
              </p>
              <button className='hidden group-hover:flex justify-center w-[80%] items-center bg-blue-700 text-white rounded-full py-2'>
                არჩევა
              </button>
            </div>
          </div>
        </div>
      </div>
    </SlideFromBottom>
  )
}

export default SpecialOfferComponent