import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Link, useLocation, useParams } from "react-router-dom";
import { CardMain } from "../categoryClotherPage/cardComponents/CardMain";

const CategoryId = () => {
  const params = useParams();
  const { id } = params;
  const { pathname } = useLocation();
  const prevPage = pathname.split("/")[2];
  const [womenData, setWomenData] = useState([
    {
      id: 1,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 120,
      style: "3",
      color: [
        {
          id: 1,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504",
          size: ["m", "l", "xl"],
        },
        {
          id: 3,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504",
          size: ["m", "xl"],
        },
        {
          id: 4,
          name: "ნაცრისფერი",
          name1: "slate",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504",
          size: ["m", "xl", "xxl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357",
    },
    {
      id: 2,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "1",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 3,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "2",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["s", "m", "l", "xl"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
    {
      id: 4,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "4",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 5,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["xs", "s", "m"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["xs", "m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
    {
      id: 6,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "1",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 7,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "2",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["s", "m", "l", "xl"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
    {
      id: 8,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504",
          size: ["m", "l", "xl"],
        },
        {
          id: 3,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504",
          size: ["m", "xl"],
        },
        {
          id: 4,
          name: "ნაცრისფერი",
          name1: "slate",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504",
          size: ["m", "xl", "xxl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357",
    },
    {
      id: 9,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "4",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 10,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["xs", "s", "m"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["xs", "m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
  ]);
  const [menData, setMenData] = useState([
    {
      id: 1,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "1",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 2,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "2",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["s", "m", "l", "xl"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
    {
      id: 3,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504",
          size: ["m", "l", "xl"],
        },
        {
          id: 3,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504",
          size: ["m", "xl"],
        },
        {
          id: 4,
          name: "ნაცრისფერი",
          name1: "slate",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504",
          size: ["m", "xl", "xxl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357",
    },
    {
      id: 4,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "4",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 5,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["xs", "s", "m"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["xs", "m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
    {
      id: 6,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "1",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 7,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "2",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["s", "m", "l", "xl"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
    {
      id: 8,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504",
          size: ["m", "l", "xl"],
        },
        {
          id: 3,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504",
          size: ["m", "xl"],
        },
        {
          id: 4,
          name: "ნაცრისფერი",
          name1: "slate",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504",
          size: ["m", "xl", "xxl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357",
    },
    {
      id: 9,
      title: "ქურთუკი",
      name: "mens jacket",
      style: "4",
      price: 47,
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504",
          size: ["m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357",
    },
    {
      id: 10,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style: "3",
      color: [
        {
          id: 1,
          name: "წითელი",
          name1: "red",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357",
          size: ["xs", "s", "m", "l", "xl"],
        },
        {
          id: 2,
          name: "ლურჯი",
          name1: "blue",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504",
          size: ["xs", "s", "m"],
        },
        {
          id: 3,
          name: "მწვანე",
          name1: "green",
          src1: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
          size: ["xs", "m", "l", "xl"],
        },
      ],
      img: "https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504",
      img1: "https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState([]);

  const [data, setData] = useState([]);
  useEffect(() => {
    if (prevPage === "women") {
      setData([...womenData]);
    }
    if (prevPage === "men") {
      setData([...menData]);
    }
  }, []);
  useEffect(() => {
    const newSelectedItem = data.filter((item) => item.id == id);
    setSelectedItem(newSelectedItem);
  }, [id, data]);

  return (
    <div className="w-full h-auto flex flex-col">
      <Header />
      <div className="w-full h-auto px-[15%] flex mt-28">
        <div className="w-full flex gap-2 text-xs">
          <Link to="/">
            <p className="hover:text-blue-600">Home</p>
          </Link>
          <p>/</p>
          <Link to={`/category/${prevPage}`}>
            <p className="hover:text-blue-600">{prevPage}</p>
          </Link>
          <p>/</p>
          <p>{id}</p>
          {selectedItem.length !== 0 && <h1>{selectedItem[0].title}</h1>}
        </div>
      </div>
      <div className="w-full px-[15%] flex ">
        
      </div>
      <div className="w-full h-auto flex justify-center px-[15%] flex-col gap-10 py-10">
        <h1 className="text-center">თქვენთვის</h1>
        <div className="w-full h-auto grid grid-cols-4 gap-4 pb-10">
          {data.map((item) => (
            <Link to={`/category/${prevPage}/${item.id}`}>
              <CardMain key={item.id} item={item} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryId;
