import React from 'react'
import WhiteLogo from './image/logo.png'
import {BsInstagram,BsTwitter,BsYoutube} from 'react-icons/bs'
import {FaTiktok,FaGooglePay,FaApplePay} from 'react-icons/fa'
import {GrAmex} from 'react-icons/gr'
import {ImFacebook} from 'react-icons/im'
import {RiVisaLine} from 'react-icons/ri'
import {SiAmazonpay} from 'react-icons/si'
const Footer = () => {
  return (
    <div className='w-full flex flex-col bg-blue-600 '>
      <div className='w-full h-auto flex items-center justify-between px-[10%] '>
        <div className='w-[33%] h-auto  flex flex-col py-24 gap-7'>
          <img className='w-36' src={WhiteLogo} alt=''/>
          <p className='text-white'>შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის</p>
          <div className='flex gap-5'>
            <div className='w-[30px] h-[30px] text-white'>
              <BsInstagram className='w-full h-full '/>
            </div>
            <div className='w-[30px] h-[30px] text-white'>
              <ImFacebook className='w-full h-full '/>
            </div>
            <div className='w-[30px] h-[30px] text-white'>
              <FaTiktok className='w-full h-full '/>
            </div>
            <div className='w-[30px] h-[30px] text-white'>
              <BsTwitter className='w-full h-full '/>
            </div>
            <div className='w-[30px] h-[30px] text-white'>
              <BsYoutube className='w-full h-full '/>
            </div>
          </div>
        </div>
        <div className='w-[60%] h-auto flex '>
          <div className='w-[33%] flex flex-col gap-7' >
            <h1 className='uppercase text-white'>ინფორმაცია</h1>
            <div className='w-full flex flex-col text-white gap-3 '>
              <p>ჩვენ შესახებ</p>
              <p>ძებნა</p>
              <p>კონტაქტი</p>
              <p>სიახლეები</p>
            </div>
          </div>
          <div className='w-[33%] flex flex-col gap-7' >
            <h1 className='uppercase text-white'>დამატებითი ბმულები</h1>
            <div className='w-full flex flex-col text-white gap-3 '>
              <p>კონფიდენციალურობის პოლიტიკა</p>
              <p>წესები და პირობები</p>
              <p>დოკუმენტაცია</p>
              <p>საგადახდო სისტემები</p>
              <p>მიწოდების პოლიტიკა</p>
            </div>
          </div>
          <div className='w-[33%] h-full flex flex-col gap-7 justify-start' >
            <h1 className='uppercase text-white'>კონტაქტები</h1>
            <div className='w-full flex flex-col text-white gap-3 '>
              <p>ტელეფონი: <a>2 11 11 90</a></p>
              <p>ელ ფოსტა: <a>Sales@Bmsoft.dev</a></p>
              <p>მისამართი: <a>წერეთლის გამზირი #116, თბილისი</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex py-5 justify-between items-center border-t border-white px-[20%]'>
        <h1 className='text-white '>© 2023, SUPPORTED BY BMSOFT </h1>
        <div className='flex gap-2'>
          <div className='h-33 w-[40px] bg-blue-700 flex items-center justify-center rounded-md' >
            <GrAmex className='w-[80%] h-[80%] text-white'/>
          </div>
          <div className='h-33 w-[40px] bg-white border border-black flex items-center justify-center rounded-md' >
            <FaApplePay className='w-[80%] h-[80%] text-black'/>
          </div>
          <div className='h-33 w-[40px] bg-white flex items-center justify-center rounded-md' >
            <FaGooglePay className='w-[80%] h-[80%]'/>
          </div>
          <div className='h-33 w-[40px] bg-white flex items-center justify-center rounded-md' >
            <SiAmazonpay className='w-[80%] h-[80%]'/>
          </div>
          <div className='h-33 w-[40px] bg-white flex items-center justify-center rounded-md' >
            <RiVisaLine className='w-[80%] h-[80%] text-blue-950'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer