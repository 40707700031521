import React from 'react'
import SlideFromBottom from '../Animations/SlideFromBottom'
import { Link } from 'react-router-dom'

const Category = () => {
  return (
    <SlideFromBottom>
      <div className="w-full py-[50px] px-auto max-xl:px-[20%] max-lg:px-[10%] max-md:px-[3%]  h-auto flex justify-center">
        <div className="w-[75%] max-2xl:w-[90%] max-xl:w-full max-xl:gap-y-5 max-xl:flex-wrap h-auto flex justify-evenly">
              <Link 
                className="w-[22%]  max-xl:w-[40%] max-md:w-[51%] max-w-[330px] h-[540px] group flex flex-col gap-3 rounded-xl bg-white  shadow-2xl"
                to='category/women'>
                <div className="w-full h-[85%] flex items-end justify-center">
                  <img className='w-full h-full rounded-xl ' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-01_6e022e00-a7b9-4d9f-8b57-fabda36afc4e.png?v=1664377618&width=270' alt=''/>
                    <h1 className='absolute mb-[20px] text-white text-2xl group-hover:-translate-y-6 duration-300'>ქალი</h1>
                </div>
                <div className='w-full h-auto flex px-10 justify-between'>
                  <div className='flex flex-col items-center group-hover:text-blue-700'>
                    <h1 className=' text-xl'>45</h1>
                    <p className='text-xs'>პროდუქტი</p>
                  </div>  
                  <div className='h-full w-[1px] bg-slate-300'></div>
                  <div className='flex flex-col items-center group-hover:text-blue-700'>
                    <h1 className=' text-xl'>15%</h1>
                    <p className='text-xs'>ფასდაკლება</p>
                  </div> 
                </div>
              </Link>  
            <Link className="w-[22%] max-w-[330px] max-xl:w-[40%] max-md:w-[51%] h-[540px] group flex flex-col gap-3 rounded-xl bg-white  shadow-2xl" 
              to='category/men'>
              <div className="w-full h-[85%] flex items-end justify-center">
                <img className='w-full h-full rounded-xl ' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-02.png?v=1677655197&width=270' alt=''/>
                  <h1 className='absolute mb-[20px] text-white text-2xl group-hover:-translate-y-6 duration-300'>კაცი</h1>
              </div>
              <div className='w-full h-auto flex px-10 justify-between'>
                <div className='flex flex-col items-center group-hover:text-blue-700'>
                  <h1 className=' text-xl'>85</h1>
                  <p className='text-xs'>პროდუქტი</p>
                </div>  
                <div className='h-full w-[1px] bg-slate-300'></div>
                <div className='flex flex-col items-center group-hover:text-blue-700'>
                  <h1 className=' text-xl'>30%</h1>
                  <p className='text-xs'>ფასდაკლება</p>
                </div> 
              </div>
            </Link>
          <div className="w-[22%] max-w-[330px]  max-xl:w-[40%] h-[540px] max-md:w-[51%] group flex flex-col gap-3 rounded-xl bg-white  shadow-2xl">
            <div className="w-full h-[85%] flex items-end justify-center">
              <img className='w-full h-full rounded-xl ' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-03.png?v=1664530414&width=270' alt=''/>
              <h1 className='absolute mb-[20px] text-white text-2xl group-hover:-translate-y-6 duration-300'>ბავშვი</h1>
            </div>
            <div className='w-full h-auto flex px-10 justify-between'>
              <div className='flex flex-col items-center group-hover:text-blue-700'>
                <h1 className=' text-xl'>77</h1>
                <p className='text-xs'>პროდუქტი</p>
              </div>  
              <div className='h-full w-[1px] bg-slate-300'></div>
              <div className='flex flex-col items-center group-hover:text-blue-700'>
                <h1 className=' text-xl'>20%</h1>
                <p className='text-xs'>ფასდაკლება</p>
              </div> 
            </div>
          </div>
          <div className="w-[22%] max-w-[330px]  max-xl:w-[40%] h-[540px] max-md:w-[51%] group flex flex-col gap-3 rounded-xl bg-white  shadow-2xl">
            <div className="w-full h-[85%] flex items-end justify-center">
              <img className='w-full h-full rounded-xl ' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-04.png?v=1669380333&width=270' alt=''/>
              <h1 className='absolute mb-[20px] text-white text-2xl group-hover:-translate-y-6 duration-300'>ფასდაკლება</h1>
            </div>
            <div className='w-full h-auto flex px-10 justify-between'>
              <div className='flex flex-col items-center group-hover:text-blue-700'>
                <h1 className=' text-xl'>63</h1>
                <p className='text-xs'>პროდუქტი</p>
              </div>  
              <div className='h-full w-[1px] bg-slate-300'></div>
              <div className='flex flex-col items-center group-hover:text-blue-700'>
                <h1 className=' text-xl'>18%</h1>
                <p className='text-xs'>ფასდაკლება</p>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </SlideFromBottom>
  )
}

export default Category