import React from 'react'
import Counter from '../../homePage/Counter';
import { BsBasket } from 'react-icons/bs';

const HoverStyle1 = ({item,colorValue,setColorValue,setSizeValue,setImgChange,sizeValue,cartShow,addCart,setDef,setCounterValue1,def}) => {
  const itemStyle=item.style
  return (
    <div className="w-full h-0 group-hover:h-[50%] gap-3 rounded-lg border border-slate-200 bg-white absolute mb-0  
        flex-col items-center flex opacity-0 group-hover:opacity-100 overflow-hidden duration-500">
          <h1 className="capitalize font-semibold mt-3 text-[12px] opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">{item.name}</h1>
          <div className="flex flex-col opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
            <h1 className="text-center mb-[1px] text-[12px]">color:{colorValue}</h1>
            <div className="flex gap-1">
              {item.color.map((item,index) => (
                <div
                  key={index}
                  onMouseOver={() => {
                    setColorValue(item.name1);
                    setSizeValue("");
                  }}
                  onClick={() => {
                    setColorValue(item.name1);
                    setSizeValue("");
                    setImgChange(item.src1)
                  }}
                  className={`
                  ${itemStyle==='1'?
                    `border-[1px] px-2 flex justify-center items-center rounded-md py-[1px] w-[45px] `
                    :
                    `border-2  ${itemStyle==='4' && `bg-${item.name1}-600`} shadow-md  w-8 flex justify-center rounded-md h-8 `
                    }
                  `}
              >
                {item.src1 && itemStyle==='3'&& <img  className=' ' src={item.src1} alt=''/>}
                {itemStyle === '1' && <p className="text-slate-700 text-[12px]">{item.name1}</p>}
              </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
            <h1 className="text-center mb-[1px] text-[12px]">Size:{sizeValue}</h1>
            <div className="flex gap-1">
              {item.color
                .filter((item) => item.name1 === colorValue)[0]
                .size.map((item,index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSizeValue(item);
                    }}
                    className={`border-[2px] px-[5px] flex justify-center items-center rounded-md py-[1px] ${item.length > 4 ?'' :' w-[30px]'}   
                    shadow-md
                    ${
                      sizeValue === item
                        ? "border-blue-500"
                        : "border-slate-200"
                    }`}
                  >
                    <p className="text-[12px]">{item}</p>
                  </button>
                ))}
            </div>
          </div>
          <div className='flex w-full items-center justify-evenly px-5'>
            <div className="w-[53%] flex flex-col  opacity-0 items-center justify-center group-hover:opacity-100 ease-linear delay-300 duration-200">
              <div className="w-full bg-blue-600 rounded-full h-auto flex gap-2  items-center justify-center ">
                <BsBasket className="text-white"/>
                <button 
                  onClick={() =>{
                    cartShow.setCartIsShow(true);
                    addCart(item);
                    setDef((prev) => prev+1);
                  }}
                  // onClick={() => {
                  //   setPopUpShow(true);
                  //   setSelectedItem(item);
                  // }}
                  className="text-sm  text-center py-[10px] text-white">
                  დამატება
                </button>
              </div>
            </div>
            <div className="w-[30%]">
              <Counter  setValue={setCounterValue1} test1={def} test={false}  />
            </div>
          </div>
        </div>
  )
}

export default HoverStyle1