import { useContext, useEffect, useState } from "react";
import Counter from "../Counter";
import { MdStarRate } from "react-icons/md";
import { TbCurrencyLari } from "react-icons/tb";
import { BsBasket } from "react-icons/bs";
import CartShow from "../../../contextComponents/CartShow";
import CartData from "../../../contextComponents/CartData";
export const SwiperSliders = ({ item, setPopUpShow, setSelectedItem }) => {
  const [colorValue, setColorValue] = useState("blue");
  const [sizeValue, setSizeValue] = useState("");
  const [imgChange, setImgChange] = useState(item.img);
  const cartShow = useContext(CartShow)
  const cartData = useContext(CartData)
  const [counterValue1, setCounterValue1] = useState(1)
  const [def,setDef] = useState(1)
  const addCart = (items) =>{
    if(counterValue1>0){
      const filtered=cartData.cartData.find((item) => item.id===items.id)
      if(filtered)
      {
        // if((colorValue !== filtered.colorValue) && (sizeValue !== filtered.sizeValue)){
        //   cartData.cartData.map((item)=>{
        //     if(items.id===item.id)
        //     {
        //       item.counter=counterValue1+item.counter
        //     }
        //   })
        //     localStorage.setItem('items',JSON.stringify(cartData.cartData))
        // }
        // else{
        //   const newCart=[{counter:counterValue1, colorValue:colorValue ,sizeValue:sizeValue, ...items},...cartData.cartData]
        //   cartData.setCartData(newCart)
        //   localStorage.setItem('items',JSON.stringify(newCart))
        // }
        cartData.cartData.map((item)=>{
          if(items.id===item.id)
          {
            item.counter=counterValue1+item.counter
          }
        })
          localStorage.setItem('items',JSON.stringify(cartData.cartData))
      }
      else
      {
        const newCart=[{counter:counterValue1, colorValue:colorValue ,sizeValue:sizeValue, ...items},...cartData.cartData]
        cartData.setCartData(newCart)
        localStorage.setItem('items',JSON.stringify(newCart))
      }
      // console.log(items.colorValue)
      // console.log(filtered.colorValue)
      // console.log(filtered)
    }
  }

  return (
    <div
      onMouseOver={() => setImgChange(item.img1)}
      onMouseOut={() => setImgChange(item.img)}
      className="w-full h-[90%] bg-white  flex-col rounded-lg flex 
      pt-5 gap-5 justify-end group duration-300 border border-slate-200"
    >
      <div className="flex flex-col">
        <div className="flex justify-between items-center p-3">
          <h1 className="uppercase text-base ">{item.title}</h1>
          <div className="flex gap-1 items-center ">
            <div className="flex items-center">
              <div className='w-5 h-5 flex justify-center items-center font-thin'>
                <TbCurrencyLari className={`w-[90%] h-[90%] text-black  `}/>
              </div>
              <h1 className=" text-base">{item.price}</h1>
            </div>
            <div className="flex items-end mt-[5px] line-through">     
              <h1 className=" text-sm text-slate-500">{item.oldPrice}</h1>
            </div>
          </div>
        </div>
        <img className="w-full h-[300px] " src={imgChange} alt="" />
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex gap-1 justify-center">
          {item.color.map((item, index) => (
            <button
              key={index}
              onMouseOver={() => {
                setColorValue(item.name1);
                setSizeValue("");
              }}
              onClick={() => {
                setColorValue(item.name1);
                setSizeValue("");
              }}
              className={`border-[1px] px-2 flex justify-center items-center rounded-md py-[1px] w-[45px] `}
            >
              <p className="text-slate-700 text-[12px]">{item.name1}</p>
            </button>
          ))}
        </div>
        <h1 className="text-center capitalize text-sm font-semibold mb-6 ">
          {item.name}
        </h1>
        {/* <div className="flex gap-1 justify-center mb-4">
          <MdStarRate />
          <MdStarRate />
          <MdStarRate />
          <MdStarRate />
          <MdStarRate />
        </div> */}
      </div>
      <div
        className="w-full  h-0 group-hover:h-[42%] pb-3 gap-3 border border-slate-200 rounded-lg  bg-white absolute mb-0  
      flex-col items-center flex opacity-0 group-hover:opacity-100 overflow-hidden duration-500"
      >
        <h1 className="capitalize font-semibold mt-3 text-[12px] opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
          {item.name}
        </h1>
        <div className="flex flex-col opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
          <h1 className="text-center mb-[1px] text-[12px]">
            color: {colorValue}
          </h1>
          <div className="flex gap-1">
            {item.color.map((item, index) => (
              <button
                key={index}
                onMouseOver={() => {
                  setColorValue(item.name1);
                  setSizeValue("");
                }}
                onClick={() => {
                  setColorValue(item.name1);
                  setSizeValue("");
                }}
                className={`border-[1px] px-2 flex justify-center items-center rounded-md py-[1px] w-[45px]  ${
                  colorValue === item.name1
                    ? "border-blue-500"
                    : "border-slate-200"
                }`}
              >
                <p className="text-slate-700 text-[12px]">{item.name1}</p>
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col opacity-0 group-hover:opacity-100 ease-linear delay-300 duration-200">
          <h1 className="text-center mb-[1px] text-[12px]">Size:{sizeValue}</h1>
          <div className="flex gap-1">
            {item.color
              .filter((item) => item.name1 === colorValue)[0]
              .size.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setSizeValue(item);
                  }}
                  className={`border-[1px] px-[5px] flex justify-center items-center rounded-md py-[1px] w-[30px] 
                  shadow-md
                  ${
                    sizeValue === item ? "border-blue-500" : "border-slate-200"
                  }`}
                >
                  <p className="text-[12px] text-slate-700">{item}</p>
                </button>
              ))}
          </div>
        </div>
        <div className='flex w-full items-center justify-evenly px-5'>
          <div className="w-[53%] flex flex-col  opacity-0 items-center justify-center group-hover:opacity-100 ease-linear delay-300 duration-200">
            <div className="w-full bg-blue-600 rounded-full h-auto flex gap-2  items-center justify-center ">
              <BsBasket className="text-white"/>
              <button 
                onClick={() =>{
                  cartShow.setCartIsShow(true);
                  addCart(item);
                  setDef((prev) => prev+1);
                }}
                // onClick={() => {
                //   setPopUpShow(true);
                //   setSelectedItem(item);
                // }}
                className="text-sm  text-center py-[10px] text-white">
                დამატება
              </button>
            </div>
          </div>
          <div className="w-[30%]">
            <Counter setValue={setCounterValue1} test1={def} test={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
