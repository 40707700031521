import React from 'react'
import {BsXLg} from 'react-icons/bs'
const MainPopUp = ({setIsMainPopUpShow}) => {
  return (
    <div className='w-screen top-0 left-0 h-screen flex justify-center items-center fixed bg-black bg-opacity-80 z-50'>
      <div className='w-[73%] h-[75%] bg-white rounded-2xl flex '>
        <div className='w-1/2 h-full '>
          <img
            className='rounded-l-2xl w-full h-full'
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/popup-login.png?v=1669998252&width=798' alt=''/>
        </div>
        <div className="w-1/2 h-full flex flex-col justify-center items-center px-[10%] gap-5">
          <div className="flex  flex-col justify-center items-center gap-2">
            <h1 className='text-2xl'>გაფრთილება</h1>
            <p className="text-slate-500 text-xs text-center">შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და 
            ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული</p>
          </div>
        </div>
        <div>
          <BsXLg
          onClick={() => setIsMainPopUpShow(false)}
          className='w-7 h-7 cursor-pointer mr-5 mt-5'/>
        </div>
      </div>
    </div>
  )
}

export default MainPopUp