import React, { useEffect } from "react";
import { useState } from "react";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

const Select = ({ name = "აირჩიეთ",test, setValue1}) => {
  const [value, setValue] = useState(name);
  const [show, setShow] = useState(false);
  const [data, setData] = useState(test);

  useEffect(()=>{
    if(setValue1){
      setValue1(value)
    }
  },[value])
  const handelClick = (item) => {
    setShow(false)
    setValue(item)
  }

  return (
    <div className="flex flex-col gap-2 w-56 relative ">
      <div 
        onClick={() => setShow(!show)}
        className="w-full flex items-center justify-between border-2 border-slate-200 rounded-full bg-white px-3 py-2">
        <p className=" text-xs ">{value}</p>
        <div >
          {show ? <BiChevronUp /> : <BiChevronDown />}
        </div>
      </div>
      {show ? (
        <div
          className="bg-white w-full h-auto z-[5] overflow-auto rounded-xl flex flex-col p-2
        absolute top-[105%] left-0 gap-[4px] border-2 border-slate-200"
        >
          {data.map((item) => (
            <p
              onClick={() => handelClick(item)}
              className="px-2 rounded-lg hover:bg-slate-300
              text-xs"
            >
              {item}
            </p>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Select;
