import React from 'react'
import {BsArrowRightShort} from 'react-icons/bs'
import WomenDivs from './navigationHeadComponents/WomenDivs'
import MenDivs from './navigationHeadComponents/MenDivs'
import ChildrenDiv from './navigationHeadComponents/ChildrenDiv'
import SelfCare from './navigationHeadComponents/SelfCare'
import Mission from './navigationHeadComponents/Mission'
import FoodAdditives from './navigationHeadComponents/FoodAdditives'
const NavigationHead = () => {
  return (
    <div className='w-[24%] flex flex-col'>
      <div className='w-full h-auto bg-blue-600 rounded-2xl px-5'>
        <h1 className='py-4 text-white '>კატეგორიები</h1>
      </div>
      <div className='flex flex-col gap-5 py-7 bg-slate-100 rounded-2xl relative'>
        <div className='flex justify-between items-center group px-7'>
          <h1>ქალი</h1>
          <BsArrowRightShort className='text-black w-7 h-7 '/>
          <WomenDivs/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>კაცი</h1>
          <BsArrowRightShort className='text-black w-7 h-7'/>
          <MenDivs/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>ბავშვი</h1>
          <BsArrowRightShort className='text-black w-7 h-7'/>
          <ChildrenDiv/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>თავის მოვლა</h1>
          <BsArrowRightShort className='text-black w-7 h-7'/>
          <SelfCare/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>მიზნის მიხედვით</h1>
          <BsArrowRightShort className='text-black w-7 h-7 group'/>
          <Mission/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>თავის მოვლა</h1>
          <BsArrowRightShort className='text-black w-7 h-7'/>
          <SelfCare/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>საკვები დანამატები</h1>
          <BsArrowRightShort className='text-black w-7 h-7'/>
          <FoodAdditives/>
        </div>
        <div className='flex justify-between items-center group px-7'>
          <h1>თავის მოვლა</h1>
          <BsArrowRightShort className='text-black w-7 h-7'/>
          <Mission/>
        </div>
      </div>
    </div>
  )
}

export default NavigationHead