import React, { useState } from 'react'
import {MdStarRate} from 'react-icons/md'
import {TbCurrencyLari} from 'react-icons/tb'
const FeaturedItemsComponent = ({item}) => {
  const [changeImg,setChangeImg] = useState(item.src)
  return (
    <div 
      className='w-[49%] h-auto flex py-1'>
      <div className='w-[23%] h-auto'
        onMouseOver={() => setChangeImg(item.src1)}
        onMouseOut={() => setChangeImg(item.src)}
        >
        <img 
          className='w-full h-full rounded-md'
          src={changeImg} alt=''/>
      </div>
      <div className='w-[75%] h-auto flex flex-col gap-2 p-2 '>
        <div className='flex flex-col'>
          <p>{item.title}</p>
          <div className="flex items-center">
            <div className='w-5 h-5 flex justify-center items-center font-thin'>
              <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
            </div>
            <h1 className="text-lg">{item.price}</h1>
          </div>
        </div>
        <div className='flex gap'>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
        </div>
      </div>
    </div>
  )
}

export default FeaturedItemsComponent