import React from 'react'
import {IoMdArrowDropright} from 'react-icons/io'

const ChildrenDiv = () => {
  return (
    <div className='w-0 h-[500px] duration-200 overflow-hidden  group-hover:w-[320%] absolute left-[100%] top-0 z-[5]'>
      <div className='flex w-full h-full bg-white rounded-lg justify-around'>
        <div className='w-[20%] overflow-hidden h-full flex flex-col py-7 pl-10'>
          <h1 className='hover:text-blue-600 cursor-pointer'>ფეხსაცმელი</h1>
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
        </div>
        <div className='w-[20%] overflow-hidden h-full flex flex-col py-7 pl-10'>
          <h1 className='hover:text-blue-600 cursor-pointer'>ტანსაცმელი</h1>
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
        </div>
        <div className='w-[20%] overflow-hidden h-full flex flex-col py-7 pl-10'>
          <h1 className='hover:text-blue-600 cursor-pointer'>აქსესუარები</h1>
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright />
            <p className='text-sm'>ჯინსი</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600  items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>შარვალი</p>
          </div>    
        </div>

      </div>


    </div>
  )
}

export default ChildrenDiv