import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Grid, Pagination } from "swiper";
import SpecialOfferComponent from "./SpecialOfferComponent";
const SpecialOffer = () => {
  const [data,setData] = useState([
    { 
      id:1,
      name:"REALTANK",
      name1:"ლეპტოპი",
      price:'657.00',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ",
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_0b7a6ec9-b36b-43ad-898d-3fe375ac49af.png?v=1676471488&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e9e5bd2c-5bb4-47cb-a342-76a013b3394d.png?v=1676471487&width=357'
    },
    { 
      id:2,
      name:"SAMSUNG",
      name1:"ტელევიზორი",
      price:'360',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ",
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_53217a6f-7f6a-4329-b7e7-9e1bd4b4635d.png?v=1676470071&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_92cf3fa0-2c6c-4708-b6fd-88510a266488.png?v=1676470071&width=357'
    },
    { 
      id:3,
      name:"REALTANK",
      name1:"ლეპტოპი",
      price:'657.00',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ",
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_0b7a6ec9-b36b-43ad-898d-3fe375ac49af.png?v=1676471488&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e9e5bd2c-5bb4-47cb-a342-76a013b3394d.png?v=1676471487&width=357'
    },
    { 
      id:4,
      name:"SAMSUNG",
      name1:"ტელევიზორი",
      price:'360',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად მიახლოებული შაბლონი წარუდგინონ შემფასებელს. ",
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_53217a6f-7f6a-4329-b7e7-9e1bd4b4635d.png?v=1676470071&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_92cf3fa0-2c6c-4708-b6fd-88510a266488.png?v=1676470071&width=357'
    },
    
  ])
  return (
    <div className="w-full h-auto pb-20 px-5">
      <Swiper
        slidesPerView={2}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Grid,Pagination]}
        className="test2 w-full h-[430px]"
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <SpecialOfferComponent item={item}/>
          </SwiperSlide>
        ))}
          
      </Swiper>
    </div>
  )
}

export default SpecialOffer