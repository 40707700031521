import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import "./styles.css";
// import required modules
import { Grid, Pagination } from "swiper";
import RecommendedComponent from "./RecommendedComponent";
import RecommendedPopUp from "./RecommendedPopUp";
import ScrollBarVisible from "../../contextComponents/ScrollBarVisible";
const Recommended = () => {
  const [popUpShow, setPopUpShow] = useState(false)
  const scrollingStop=useContext(ScrollBarVisible)
  const [selectedItem, setSelectedItem] = useState({});
  const [data,setData] = useState([
    { 
      id:1,
      name:"კაბა",
      name1:'Slim dress linen style',
      price:'27',
      oldPrice:'67',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_45a9613e-b10c-46a8-ad71-8cbc20801003.png?v=1678266175&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/6_3be20f44-96bf-4bb4-b006-5454252e45c1.png?v=1678266175&width=357'
    },
    { 
      id:2,
      name:'პერანგი',
      name1:'Slim dress linen style',
      price:'20',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_5e7be902-a63d-428e-bb55-ff7691f1a3fc.png?v=1674415763&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_19850676-d62b-4536-a5a6-c895da1124fe.png?v=1674488735&width=357'
    },
    { 
      id:3,
      name:"კაბა",
      name1:'Slim dress linen style',
      price:'21',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f0bf1969-f016-4a8b-9633-610002fdffb8.png?v=1674634116&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_c59e7b6f-a9d5-486c-a682-b8220b54d30f.png?v=1674634114&width=357'
    },
    { 
      id:4,
      name:"შარვალი",
      name1:'Slim dress linen style',
      price:'39',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/7_ffde0590-f283-4ce1-a48c-a08c19b1e7eb.png?v=1674466169&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f444e160-0f41-4566-a303-ee80c0714e72.png?v=1674466169&width=357'
    },
    { 
      id:5,
      name:"ქურთუკი",
      name1:'Slim dress linen style',
      price:'88',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_844420b0-4baa-4531-8828-698e2e435d75.png?v=1678265625&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_a0a998a3-f996-4558-906f-1b1eae1f55cb.png?v=1678265625&width=357'
    },
    { 
      id:6,
      name:"პერანგი",
      name1:'Slim dress linen style',
      price:'14',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_11.png?v=1677915035&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_14589585-4199-458b-99cf-fca598208789.png?v=1677915035&width=357'
    },
    { 
      id:7,
      name:"კაბა",
      name1:'Slim dress linen style',
      price:'32',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e578af20-014e-446f-88de-3bc12da4f662.png?v=1674597052&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_46a4c232-ea62-4f85-9e70-ae8a20ffed26.png?v=1674597054&width=357'
    },
    { 
      id:8,
      name:"შარვალი",
      name1:'Slim dress linen style',
      price:'38',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_4_cdbc652e-803b-415d-8a89-d824bb3de361.png?v=1674414289&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_4_783594c2-33d9-4a9f-99ad-cbf20c182387.png?v=1674414289&width=357'
    },
    { 
      id:9,
      name:"შარვალი",
      name1:'Slim dress linen style',
      price:'15',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_74f4026e-0942-4e16-8405-9e513a0a19e3.png?v=1674582122&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_2_5966030b-53b5-458b-9ca3-1fdc705b8db5.png?v=1674582123&width=357'
    },
    { 
      id:10,
      name:"შორტი",
      name1:'Slim dress linen style',
      price:'20',
      description:"შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის",
      color:[
        {
          id:1,
          name:'მწვანე',
          name1:'green',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          // src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_356a7351-feb5-478a-8e35-987b10d1abb2.png?v=1674291885&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_c6c24651-f545-4688-acb5-4375c7c02711.png?v=1674488443&width=357'
    }
  ])
  useEffect(() => {
    if(popUpShow){
      scrollingStop.setScrollingStop(false)
    }
    else{
      scrollingStop.setScrollingStop(true)
    }
  },[popUpShow])
  return (
    <div className="w-full h-auto pt-20 px-5">
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        grid={{
          rows: 2,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Grid,Pagination]}
        className="test2 w-full h-[700px]"
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <RecommendedComponent setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem}/>
          </SwiperSlide>
        ))}
          
      </Swiper>
      {popUpShow &&
      <RecommendedPopUp setPopUpShow={setPopUpShow} selectedItem={selectedItem}/>
      }
    </div>
  )
}

export default Recommended