import { useState } from "react";
import Counter from '../../homePage/Counter'
import {AiTwotoneHeart,AiFillEye} from 'react-icons/ai'
import {TbCurrencyLari} from 'react-icons/tb'
import {MdStarRate} from 'react-icons/md'
import {BsBasket} from 'react-icons/bs'

const ClotherSliderComponent = ({ item }) => {
  const [sizeValue, setSizeValue] = useState("6");
  const [imgChange, setImgChange] = useState(item.src);
  const [defCounter, setDefCounter] = useState(1);

  return (
    <div
      onMouseOver={() => setImgChange(item.src1)}
      onMouseOut={() => setImgChange(item.src)}
      className="w-full h-[90%] bg-white border shadow-xl flex-col rounded-lg flex  gap-5 justify-end group duration-300"
    >
      <div className="flex flex-col h-[70%] w-full pt-3">
        <div className="flex justify-between items-start p-3">
          <h1 className="uppercase text-sm text-slate-500 ">{item.title}</h1>
          <div className="flex items-center">
            <div className='w-5 h-5 flex justify-center items-center font-thin'>
              <TbCurrencyLari className={`w-[90%] h-[90%] text-slate-500  `}/>
            </div>
            <h1 className="text-slate-700 text-lg">{item.price}</h1>
          </div>
        </div>
        <img className="w-full h-[90%] " src={imgChange} alt="" />
      </div>
      <div className="flex flex-col items-center mt-5 gap-3 h-[30%]">
        {
          (item.size && item.category !=='boots') &&
          <div className="flex gap-1">
            {item.size.map((item,index) => (
                <button
                  key={index}
                  onClick={() => {
                    setSizeValue(item);
                  }}
                  className={`border-[2px] px-[5px] flex justify-center items-center hover:border-[#fe9a7e]  py-[3px] w-[30px]  ${
                    sizeValue === item
                      ? "border-[#fe9a7e]"
                      : "border-slate-200"
                  }`}
                >
                  <p className={` text-[9px] hover:text-[#fe9a7e] w-full h-full ${sizeValue === item ? 'text-[#fe9a7e]':'text-slate-500'}`}>{item}</p>
                </button>
              ))}
            </div>
        }
        <h1 className="text-center capitalize text-sm hover:text-[#fe9a7e] cursor-pointer ">{item.name}</h1>
        <div className='flex gap-1 justify-center'>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
        </div>
      </div>
      <div className="w-full h-auto py-5 gap-5 rounded-lg shadow-2xl bg-white absolute mb-0  flex-col items-center flex opacity-0 group-hover:opacity-100 duration-500">
        <h1 className="capitalize text-sm mt-3 hover:text-[#fe9a7e] cursor-pointer">{item.name}</h1>
        {
          item.size &&
          <div className="flex flex-col gap-2">
            <h1 className="text-center mb-[1px] text-xs text-slate-700">ზომა:{sizeValue}</h1>
            <div className="flex gap-1">
              {item.size.map((item,index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSizeValue(item);
                    }}
                    className={`border-[2px] px-[5px] flex justify-center items-center hover:border-[#fe9a7e]  py-[3px] w-[30px]  ${
                      sizeValue === item
                        ? "border-[#fe9a7e]"
                        : "border-slate-300"
                    }`}
                  >
                    <p className={` text-[9px] hover:text-[#fe9a7e] w-full h-full ${sizeValue === item ? 'text-[#fe9a7e]':'text-slate-500'}`}>{item}</p>
                  </button>
                ))}
            </div>
          </div>
        }
        <div className="flex flex-col gap-3">
          <Counter def={defCounter} />
          <div className="w-full bg-[#fe9a7e] h-auto flex gap-3 items-center justify-center px-[10%] ">
            <BsBasket className="text-white"/>
            <button className="text-sm  text-center py-2 text-white">
              დამატება
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClotherSliderComponent