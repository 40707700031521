import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import '../../style/main.css'

const BrandSlider = () => {
  return (
    <div className="w-full h-auto py-14">
      <Swiper
        slidesPerView={6}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="clotherSwiper w-full h-[250px]"
      >
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" w-full h-[170px] group shadow-inherit rounded-full border-[10px] border-slate-200 bg-[#ffffff] flex justify-center">
            <img className='w-[80%] h-full group-hover:w-full group-hover:shadow-md rounded-full group-hover:rounded-full duration-300'  
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/vendor-garmeen1.png?v=1676530614&width=303' alt=''/>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default BrandSlider