import {React,useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import {BsTelephone} from 'react-icons/bs'
import {RxTriangleDown} from 'react-icons/rx'
const ElectronicsHeader = () => {
  const [pages,setPages] = useState('Home')
  const [state,setState] =useState()
  window.addEventListener('scroll', function(){
    setState(window.scrollY)  
  })
  const {pathname}=useLocation()
  return (
    <div className='px-[12.5%] flex justify-between bg-[#414c55] py-2'>
      <nav className='flex gap-7 text-[10px]  py-2'>
        <Link to='/'>  
          <div className='text-yellow-300 cursor-pointer'>
            <h1>მთავარი</h1>
          </div>
        </Link>
        <div className='text-white hover:text-yellow-300 cursor-pointer'>
          <h1>ახალი</h1>
        </div>
        <div className='text-white hover:text-yellow-300 cursor-pointer'>
          <h1>გვერდები</h1>
        </div>
        <div className='text-white hover:text-yellow-300 cursor-pointer'>
          <h1>კატალოგი</h1>
        </div>
        <div className='text-white hover:text-yellow-300 cursor-pointer'>
          <h1>ქალი</h1>
        </div>
        <div className='text-white hover:text-yellow-300 cursor-pointer'>
          <h1>კაცი</h1>
        </div>
        <div className='text-white hover:text-yellow-300 cursor-pointer'>
          <h1>ფასდაკლება</h1>
        </div>
      </nav>
      <div className='flex gap-14 text-[11px]'>
        <div className='flex items-center gap-2 text-white hover:text-yellow-300'>
          <BsTelephone/>
          <p>+1-202-555-0158</p>
        </div>
        <div className='flex items-center gap-7 text-white'>
          <div className='flex gap-2 items-center hover:text-yellow-300 cursor-pointer'>
            <p>ENG</p>
            <RxTriangleDown/>
          </div>
          <div className='flex gap-2 items-center hover:text-yellow-300 cursor-pointer'>
            <p>USD</p>
            <RxTriangleDown/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ElectronicsHeader