import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper";
import SliderNavigationButton from './SliderNavigationButton';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

const FirstSlider = () => {
  return (
    <>
    <Swiper
      spaceBetween={30}
      // slidesPerView={2}
      effect={"fade"}
      // navigation = {true}
      pagination={{
        clickable: true,
      }}
      modules={[EffectFade, Pagination,Navigation]}
      className="mySwiper w-full h-full bg-slate-100"
    >
      <SwiperSlide>
        <div className='electronicsSliderCover1 rounded-2xl w-full h-full flex items-center'>
          {/* <div className='w-[60%] h-full'></div>
          <div className='flex flex-col gap-7 w-[20%]'>
            <SlideFromBottom dur={200} position={-100} >
              <h1 className='text-7xl text-yellow-500 font-black'>shopify 2.0 full sync</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={700} position={-50}>
              <p className='text-white text-sm'>The theme has been translated into all languages. Built-in support for subscriptions. 3D & Variants Ready.</p>
            </SlideFromBottom>
            <SlideFromBottom dur={2000} position={50}>
              <button className='bg-white py-2 w-[50%] text-black hover:bg-black hover:text-white text-lg font-bold rounded-full'>Start free trial store</button>
            </SlideFromBottom>
          </div> */}
        </div>
      </SwiperSlide>
      <SwiperSlide> 
        <div className='electronicsSliderCover2 w-full h-full  flex items-center rounded-2xl'>
          {/* <div className='w-[60%] h-full'></div>
          <div className='flex flex-col gap-5 w-[30%]'>
            <div className='flex flex-wrap'>
              <h1 className='text-7xl text-yellow-500 font-black flex mr-5'>29$</h1>
              <h1 className='text-7xl text-yellow-500 font-black flex line-through '>99$</h1> 
              <h1 className='text-7xl text-yellow-500 font-black flex'>weekend sale</h1>
            </div>
            <p className='text-white text-sm w-[70%]'>Shopify 2.0. Developed interface for creating your own unique pages. Sections Everywhere Ready.</p>
            <button className='bg-white py-2 w-[30%] text-black hover:bg-black hover:text-white rounded-full text-lg font-medium'>shop now</button>
          </div> */}
        </div>
      </SwiperSlide>
      <SwiperSlide> 
        <div className='electronicsSliderCover3 w-full h-full  flex items-center rounded-2xl'>
          {/* <div className='w-[60%] h-full'></div>
          <div className='flex flex-col gap-5 w-[30%]'>
            <div className='flex flex-wrap'>
              <h1 className='text-7xl text-yellow-500 font-black flex mr-5'>29$</h1>
              <h1 className='text-7xl text-yellow-500 font-black flex line-through '>99$</h1> 
              <h1 className='text-7xl text-yellow-500 font-black flex'>weekend sale</h1>
            </div>
            <p className='text-white text-sm w-[70%]'>Shopify 2.0. Developed interface for creating your own unique pages. Sections Everywhere Ready.</p>
            <button className='bg-white py-2 w-[30%] text-black hover:bg-black hover:text-white rounded-full text-lg font-medium'>shop now</button>
          </div> */}
        </div>
      </SwiperSlide>
      {/* <SliderNavigationButton/> */}
    </Swiper>
  </>
  )
}

export default FirstSlider