import React from 'react'

const Firstbanners = () => {
  return (
    <div className="w-full h-[650px] flex">
      <div className="homeBanner1 w-1/2 h-full flex items-center justify-start">
        <div className='flex flex-col pl-20 gap-5 w-1/2'>
          <h1 className="text-xs text-slate-500">ახალი კოლექცია</h1>
          <h1 className="text-[#414c55] text-2xl">მამაკაცისთვის</h1>
          <p className="text-xs text-slate-700"> შემთხვევითად გენერირებული ტექსტი 
            ეხმარება დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად
          </p>
          <div className="w-[70%] cursor-pointer group hover:bg-black h-auto py-2 px-10 bg-white mt-3 ">
            <h1 className="text-slate-400 group-hover:text-white ">სრული ნახვა</h1>
          </div>
        </div>
      </div>
      <div className="homeBanner2 w-1/2 h-full flex items-center justify-start">
        <div className='flex flex-col pl-20 gap-5 w-1/2'>
          <h1 className="text-xs text-slate-500">ახალი კოლექცია</h1>
          <h1 className="text-[#414c55] text-2xl">ქალისთვის</h1>
          <p className="text-xs text-slate-700"> შემთხვევითად გენერირებული ტექსტი ეხმარება 
            დიზაინერებს და ტიპოგრაფიული ნაწარმის შემქმნელებს, რეალურთან მაქსიმალურად
          </p>
          <div className="w-[70%] cursor-pointer group hover:bg-black h-auto py-2 px-10 bg-white mt-3">
            <h1 className="text-slate-400 group-hover:text-white ">სრული ნახვა</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Firstbanners