import React from 'react'
import { useState } from 'react'

const Category = () => {
  const [data,setData] = useState([
    {
      id:1,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-01_58d8d48f-ae89-439c-bad4-f0c204f0822f.png?v=1664481435&width=310',
      name:'კაცი'
    },
    {
      id:2,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-02.png?v=1664481462&width=310',
      name:'ქალი'
    },
    {
      id:3,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-03.png?v=1664481477&width=310',
      name:'ბავშვი'
    },
    {
      id:4,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-04.png?v=1664481490&width=310',
      name:'კაცი'
    },
    {
      id:5,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-05.png?v=1664481502&width=310',
      name:'კაცი'
    },
    {
      id:6,
      src:'https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-06.png?v=1664481513&width=310',
      name:'კაცი'
    },
  ])
  return (
    <div className="w-full h-auto py-10 px-[10%] flex justify-center">
      <div className="w-full flex h-auto justify-between">
        {
          data.map((item) => (
            <div 
              key={item.id}
              className='w-[15%] relative flex flex-col items-center justify-center '>
              <img className="w-full h-full"
              src={item.src}
              alt=''/>
              <div className="absolute w-[80%] h-auto py-2 px-3 bg-white cursor-pointer ">
                <h1 className='w-full text-black text-center'>{item.name}</h1>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Category