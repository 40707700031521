import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination,Navigation } from "swiper";
import '../../style/main.css'
import { SwiperSliders } from "./ClotherType/SwiperSliders";
import { SwiperSlider1 } from "./ClotherType/swiperSlider1";
import { SwiperSlider3 } from "./ClotherType/SwiperSlider3";
import { SwiperSlider4 } from "./ClotherType/SwiperSlider4";
import { SwiperSlider5 } from "./ClotherType/SwiperSlider5";
import RecommendedPopUp from "./RecommendedPopUp";

const ClotherSlider = () => {
  const [popUpShow, setPopUpShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState({});
  const [data,setData] = useState([
    {
      id: 1,
      title: "შორტი",
      name: "mens jacket",
      price: '47',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'წითელი',
          name1:'red',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'ნაცრისფერი',
          name1:'slate',
          size:['m','l' ,'xl']
        },
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_7d5e7880-2fc1-4b72-85b7-3bb10e38e3b1.png?v=1674549941&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_d908a544-ced8-4ce3-a8ea-26eb8fd4f94c.png?v=1674549937&width=357'
    },
    {
      id: 2,
      title: "პიჯაკი",
      name: "mens jacket",
      price: '47',
      oldPrice: '67',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'მწვანე',
          name1:'green',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_356a7351-feb5-478a-8e35-987b10d1abb2.png?v=1674291885&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_c6c24651-f545-4688-acb5-4375c7c02711.png?v=1674488443&width=357'
    },
    {
      id: 3,
      title: "ქურთუკი",
      name: "mens jacket",
      price: '47',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357'
    },
    {
      id: 4,
      title: "ქურთუკი",
      name: "mens jacket",
      price: '47',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 5,
      title: "შარვალი",
      name: "mens jacket",
      price: '47',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['30x30','34x32','34x34','32x32']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['34x32','34x34','40x40']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['28x28','40x40','44x44']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    // {
    //   id: 6,
    //   title: "შორტი",
    //   name: "mens jacket",
    //   price: '47',
    //   color:[
    //     {
    //       id:1,
    //       name:'blue',
    //       size:['xs','s','m','l' ,'xl']
    //     },
    //     {
    //       id:2,
    //       name:'yello',
    //       size:['m','l' ,'xl']
    //     },
    //     {
    //       id:3,
    //       name:'black',
    //       size:['m','l' ,'xl']
    //     },
    //     {
    //       id:4,
    //       name:'red',
    //       size:['m','l' ,'xl']
    //     }
    //   ],
    //   src:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_7d5e7880-2fc1-4b72-85b7-3bb10e38e3b1.png?v=1674549941&width=357',
    //   src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_d908a544-ced8-4ce3-a8ea-26eb8fd4f94c.png?v=1674549937&width=357'
    // },
    // {
    //   id: 7,
    //   title: "ქურთუკი",
    //   name: "mens jacket",
    //   price: '47',
    //   color:[
    //     {
    //       id:1,
    //       name:'green',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
    //       size:['xs','s','m','l' ,'xl']
          
    //     },
    //     {
    //       id:2,
    //       name:'blue',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
    //       size:['m','l' ,'xl']
    //     },
    //     {
    //       id:3,
    //       name:'red',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
    //       size:['m','xl']
    //     },
    //     {
    //       id:4,
    //       name:'yellow',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
    //       size:['m','xl','xxl']
    //     }
    //   ],
    //   src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357',
    //   src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357'
    // },
    // {
    //   id: 8,
    //   title: "ქურთუკი",
    //   name: "mens jacket",
    //   price: '47',
    //   color:[
    //     {
    //       id:1,
    //       name:'red',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
    //       size:['xs','s','m','l' ,'xl']
    //     },
    //     {
    //       id:2,
    //       name:'blue',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
    //       size:['m','l' ,'xl']
    //     }
    //   ],
    //   src:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
    //   src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    // },
    // {
    //   id: 9,
    //   title: "შარვალი",
    //   name: "mens jacket",
    //   price: '47',
    //   color:[
    //     {
    //       id:1,
    //       name:'red',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
    //       size:['30x30','34x32','34x34','32x32']
    //     },
    //     {
    //       id:2,
    //       name:'blue',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
    //       size:['34x32','34x34','40x40']
    //     },
    //     {
    //       id:2,
    //       name:'green',
    //       src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
    //       size:['28x28','40x40','44x44']
    //     }
    //   ],
    //   src:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
    //   src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    // },
    // {
    //   id: 10,
    //   title: "პიჯაკი",
    //   name: "mens jacket",
    //   price: '47',
    //   color:[
    //     {
    //       id:1,
    //       name:'blue',
    //       size:['xs','s','m','l' ,'xl']
    //     },
    //     {
    //       id:2,
    //       name:'red',
    //       size:['m','l' ,'xl']
    //     }
    //   ],
    //   src:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_356a7351-feb5-478a-8e35-987b10d1abb2.png?v=1674291885&width=357',
    //   src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_c6c24651-f545-4688-acb5-4375c7c02711.png?v=1674488443&width=357'
    // },
  ])
  return (
    <div className="w-full h-auto px-[13%]">
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        // loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination,Navigation,Autoplay]}
        className="clotherSwiper w-full h-[510px]"
      >
        {
          data.map((item) => (
            // if(item.id === 1){
            //   return(
            //     <SwiperSlide 
            //       key={item.id}
            //       className="w-[23%]">
            //       <SwiperSlider1 setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem} />
            //     </SwiperSlide>
            //   )
            // }
            // if(item.id === 2){
            //   return(
            //     <SwiperSlide 
            //       key={item.id}
            //       className="w-[23%]">
            //       <SwiperSliders  setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem}/>
            //     </SwiperSlide>
            //   )
            // }
            // if(item.id  === 3){
            //   return(
            //     <SwiperSlide 
            //       key={item.id}
            //       className="w-[23%]">
            //       <SwiperSlider3 setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem}/>
            //     </SwiperSlide>
            //   )
            // }
            // if(item.id  === 4){
            //   return(
            //     <SwiperSlide 
            //       key={item.id}
            //       className="w-[23%]">
            //       <SwiperSlider4 setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem}/>
            //     </SwiperSlide>
            //   )
            // }
            // if(item.id  === 5){
            //   return(
            //     <SwiperSlide 
            //       key={item.id}
            //       className="w-[23%]">
            //       <SwiperSlider5 setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem}/>
            //     </SwiperSlide>
            //   )
            // }
            <SwiperSlide 
              key={item.id}
              className="w-[23%]">
              <SwiperSlider1 setPopUpShow={setPopUpShow} item={item} setSelectedItem={setSelectedItem} />
            </SwiperSlide>
          ))
        }
      </Swiper>
      {popUpShow &&
      <RecommendedPopUp setPopUpShow={setPopUpShow} selectedItem={selectedItem}/>
      }
    </div>
  )
}

export default ClotherSlider