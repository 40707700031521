import React from 'react'
import { useState } from 'react'
import {AiFillEye,AiTwotoneHeart} from 'react-icons/ai'
import {BsFillEyeFill} from 'react-icons/bs'
import {BsEye} from 'react-icons/bs'
import SlideFromBottom from '../Animations/SlideFromBottom'
import {TbCurrencyLari} from 'react-icons/tb'
const RecommendedComponent = ({item,setPopUpShow,setSelectedItem}) => {
  const [change,setChange] = useState(item.img)
  // const [popUpShow, setPopUpShow] = useState(false)
  
  return (
    <SlideFromBottom>
      <div 
          key={item.id}
          onMouseOver={() => setChange(item.img1)}
          onMouseOut={() => setChange(item.img)}
        className='w-full h-[310px] p-[30px] group  flex flex-col gap-10 white border border-slate-200 rounded-xl'>
        <div className='w-full flex justify-between'>
          <h1 className='uppercase text-lg'>{item.name}</h1>
          {/* <div className='flex text-slate-300 gap-3 '>
            <AiTwotoneHeart className='w-[25px] h-0 cursor-pointer group-hover:h-[28px]  hover:scale-125 duration-300 '/>
            <AiFillEye 
              onClick={() => {setPopUpShow(true); setSelectedItem(item)}}
              className='w-[25px] h-[28px] cursor-pointer hover:scale-125 duration-300'/>
          </div> */}
        </div>
        <div className='w-full flex gap-7'>
          <img className={`w-[45%] h-[200px] rounded-xl `}
          src={change} 
          alt=''/>
          <div className='flex flex-col gap-7'>
            <div className='flex flex-col gap-3'>
              <p className='text-sm text-slate-500'>
                {item.description}
              </p>
            </div>
            <div className='flex flex-col gap-2'>
              <div className="flex gap-1 items-center ">
                <div className="flex items-center">
                  <div className='w-6 h-6 flex justify-center items-center font-thin'>
                    <TbCurrencyLari className={`w-[90%] h-[90%] text-black  `}/>
                  </div>
                  <h1 className=" text-xl">{item.price}</h1>
                </div>
                <div className="flex items-end mt-[5px] line-through">     
                  <h1 className=" text-base text-slate-500">{item.oldPrice}</h1>
                </div>
              </div>
              <button 
                // onClick={() => {setPopUpShow(true); setSelectedItem(item)}}
                className='flex duration-300 gap-2 justify-center w-[50%] items-center bg-blue-700 text-white rounded-full py-2 mt-3'>
                  <BsFillEyeFill 
                    className='w-[20px] h-[22px] cursor-pointer hover:scale-125 duration-300'/>
                    <p className='text-xs'>ნახვა</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </SlideFromBottom>
  )
}

export default RecommendedComponent