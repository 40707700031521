import React from 'react'
import {IoMdArrowDropright} from 'react-icons/io'

const Mission = () => {
  return (
    <div className='w-0  h-auto duration-200  rounded-lg  group-hover:w-full  absolute left-[100%] top-[60%] z-[5]'>
      <div className='w-full h-auto overflow-hidden'>
        <div className='flex flex-col bg-white  w-full h-full py-7 px-5'>
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>ენერგიის აღდგენა</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>ენერგიის მოსამატებლად</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>მშრალი კუნთებისთვის</p>
          </div>
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>წონის დასაკლებად</p>
          </div>    
          <div className='cursor-pointer flex hover:text-blue-600 items-center gap-2 hover:gap-0 hover:pl-3 duration-300'>
            <IoMdArrowDropright/>
            <p className='text-sm'>წონის მოსამატებლად</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mission