import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { Link, useParams } from 'react-router-dom'
import CartPopUp from '../CartPopUp'
import { CardMain } from './cardComponents/CardMain'
import {RiArrowDropDownLine,RiArrowDropUpLine} from 'react-icons/ri'
import {BsSearch} from 'react-icons/bs'
import CheckBox from './CheckBox'

const CategoryClotherPage = () => {
  const params=useParams()
  const {category}=params
  const [colorShow,setColorShow] = useState(false)
  const [sizeShow,setSizeShow] = useState(false)
  const [typeShow,setTypeShow] = useState(false)
  const [womenData,setWomenData] = useState([
    {
      id: 1,
      title: "ქურთუკი",
      name: "mens jacket",
      price:120,
      style:'3',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357'
    },
    {
      id: 2,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'1',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 3,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'2',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['s','m','l' ,'xl']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    {
      id: 4,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'4',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 5,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['xs','s','m']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['xs','m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    {
      id: 6,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'1',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 7,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'2',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['s','m','l' ,'xl']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    {
      id: 8,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357'
    },
    {
      id: 9,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'4',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 10,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['xs','s','m']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['xs','m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
  ])
  const [menData,setMenData] = useState([
    {
      id: 1,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'1',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 2,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'2',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['s','m','l' ,'xl']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    {
      id: 3,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357'
    },
    {
      id: 4,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'4',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 5,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['xs','s','m']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['xs','m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    {
      id: 6,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'1',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 7,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'2',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['s','m','l' ,'xl']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
    {
      id: 8,
      title: "ქურთუკი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=504',
          size:['xs','s','m','l' ,'xl']
          
        },
        {
          id:2,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/3_2_78e9841b-bbfe-4ab5-a9ed-d3b1ef253000.png?v=1674586539&width=504',
          size:['m','l' ,'xl']
        },
        {
          id:3,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/5_4.png?v=1674586539&width=504',
          size:['m','xl']
        },
        {
          id:4,
          name:'ნაცრისფერი',
          name1:'slate',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/4_3_2b52cb79-c9c7-4721-ab55-fb7c1a9949a1.png?v=1674586490&width=504',
          size:['m','xl','xxl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_968fe4f0-5e32-49de-8e65-1d25c53c81b6.png?v=1674550121&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_58608bd3-1673-4351-9efb-8197e46a8d4b.png?v=1674550120&width=357'
    },
    {
      id: 9,
      title: "ქურთუკი",
      name: "mens jacket",
      style:'4',
      price: 47,
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3_00ff2f26-4851-4813-a358-ce65b73b3688.png?v=1674488560&width=504',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_2_883f04f3-1338-48b4-b2e1-ad6ef431104a.png?v=1674488560&width=504',
          size:['m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_1_f86f69dd-1066-4bbf-ae19-fd9dc391beeb.png?v=1674480284&width=357',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_1_db06fcd2-9cff-4727-b02f-b4237a8707af.png?v=1674488560&width=357'
    },
    {
      id: 10,
      title: "შარვალი",
      name: "mens jacket",
      price: 47,
      style:'3',
      color:[
        {
          id:1,
          name:'წითელი',
          name1:'red',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/14_0f93734b-eb30-487f-93f2-159121f59944.png?v=1674418101&width=357',
          size:['xs','s','m','l' ,'xl']
        },
        {
          id:2,
          name:'ლურჯი',
          name1:'blue',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/8_f5083277-53bf-46e1-9edd-5478e423f536.png?v=1674418101&width=504',
          size:['xs','s','m']
        },
        {
          id:3,
          name:'მწვანე',
          name1:'green',
          src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
          size:['xs','m','l' ,'xl']
        }
      ],
      img:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_3aa399aa-13ce-4e80-9359-4bd913ef167a.png?v=1674418101&width=504',
      img1:'https://cdn.shopify.com/s/files/1/1323/8419/products/13_58eb6dd1-8c8d-452d-9dc6-27669c09dd78.png?v=1674418101&width=357'
    },
  ])
  const [data,setData] = useState([])
  const [value,setValue] = useState('0')
  useEffect(() => {
    if(category === 'women'){
      setData([...womenData])
    }
    if(category === 'men'){
      setData([...menData])
    }
  },[])
  return (
    <div className="w-full h-auto flex flex-col bg-slate-100">
      <Header/>
      <div className='w-full h-auto px-[10%] flex mt-36 gap-5'>
        <div className='w-[20%] h-auto flex  flex-col gap-7'>
          <div className='w-full h-auto flex gap-2 '>
            <Link to='/'>
              <p className='text-slate-700 text-sm hover:text-blue-600 cursor-pointer'>Home</p>
            </Link>
            <p className='text-slate-700 text-sm'>/</p>
            <p className='text-slate-700 text-sm'>{category}</p>
          </div>
          <div className='flex flex-col'>
            <h1 className='text-slate-950 text-2xl'>{category === 'women' ? 'ქალი' :'მამაკაცი'}</h1>
            <p className='text-xs text-slate-500'>რაოდენობა{data.length}</p>
          </div>
          <div className='flex items-center justify-between px-2 text-sm'>
            <div className='flex flex-col w-[40%]'>
              <h1>From</h1>
              <input className='bg-slate-100 border border-slate-500 py-1 rounded-sm' placeholder=''/>
            </div>
            <div className='w-[7%] h-[2px] bg-slate-500 mt-6'></div>
            <div className='flex flex-col w-[40%]'>
              <h1>To</h1>
              <input className='bg-slate-100 border border-slate-500 py-1 rounded-sm' placeholder=''/>
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='w-full px-2'>
              <div 
                onClick={() => setColorShow((prev) => !prev)}
                className='w-full flex py-2 hover:text-blue-500 border-b-2 border-slate-500 justify-between items-center'>
                <h1>Color</h1>
                {colorShow ? <RiArrowDropUpLine className='text-4xl font-thin'/> : <RiArrowDropDownLine className='text-4xl font-thin'/> }
              </div>
              {
                colorShow &&
                <div>
                  <div className='w-full flex gap-5 border-b-2 border-slate-500 items-center'>
                    <BsSearch className='text-xl'/>
                    <input className='bg-slate-100 py-2 outline-none' placeholder='ძებნა'/>
                  </div>
                  <div className='w-full flex flex-wrap py-2 gap-y-2 justify-between h-40 overflow-auto colorDiv'>
                    <div className='w-[45%] flex items-center gap-1'>
                      <div className='w-8 h-8 bg-black rounded-sm'>
                      </div>
                      <p className='text-sm'>შავი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-yellow-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ყვითელი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-green-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>მწვანე</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    <div className='w-[45%] flex items-center gap-1 '>
                      <div className='w-8 h-8 bg-blue-500 rounded-sm'>
                      </div>
                      <p className='text-sm'>ლურჯი</p>
                    </div>
                    
                  </div>
                </div>
              }
            </div>
            <div className='w-full px-2'>
              <div 
                onClick={() => setSizeShow((prev) => !prev)}
                className='w-full flex py-2 hover:text-blue-500 border-b-2 border-slate-500 justify-between items-center'>
                <h1>Size</h1>
                {sizeShow ? <RiArrowDropUpLine className='text-4xl font-thin'/> : <RiArrowDropDownLine className='text-4xl font-thin'/> }
              </div>
              {
                sizeShow && 
                <div className='flex flex-wrap py-2 gap-2'>
                  <div className='w-[22%] bg-white flex items-center justify-center rounded-md border border-slate-500 shadow-2xl'>
                    <p className='text-xs py-2'>XS</p>
                  </div>
                  <div className='w-[22%] bg-white flex items-center justify-center rounded-md border border-slate-500 shadow-2xl'>
                    <p className='text-xs py-2'>S</p>
                  </div>
                  <div className='w-[22%] bg-white flex items-center justify-center rounded-md border border-slate-500 shadow-2xl'>
                    <p className='text-xs py-2'>M</p>
                  </div>
                  <div className='w-[22%] bg-white flex items-center justify-center rounded-md border border-slate-500 shadow-2xl'>
                    <p className='text-xs py-2'>L</p>
                  </div>
                  <div className='w-[22%] bg-white flex items-center justify-center rounded-md border border-slate-500 shadow-2xl'>
                    <p className='text-xs py-2'>XL</p>
                  </div>
                  <div className='w-[22%] bg-white flex items-center justify-center rounded-md border border-slate-500 shadow-2xl'>
                    <p className='text-xs py-2'>XXL</p>
                  </div>
                </div>
              }
            </div>
            <div className='w-full px-2'>
              <div 
                onClick={() => setTypeShow((prev) => !prev)}
                className='w-full flex py-2 hover:text-blue-500 border-b-2 border-slate-500 justify-between items-center'>
                <h1>PRODUCT TYPE</h1>
                {typeShow ? <RiArrowDropUpLine className='text-4xl font-thin'/> : <RiArrowDropDownLine className='text-4xl font-thin'/> }
              </div>
              {
                typeShow &&
                <div>
                  <div className='w-full flex gap-5 border-b-2 border-slate-500 items-center'>
                    <BsSearch className='text-xl'/>
                    <input className='bg-slate-100 py-2 outline-none' placeholder='ძებნა'/>
                  </div>
                  <div className='flex flex-col h-40 overflow-auto colorDiv'>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                    <CheckBox title={'Dress'}/>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className='w-[80%] h-auto grid grid-cols-4 gap-4 pb-10'>
          {
            (data).map((item) => (
              <Link to={`${item.id}`}>
                <CardMain 
                  key={item.id} item={item}/>
              </Link>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default CategoryClotherPage