import React from 'react'
import { useState } from 'react'
import FeaturedItemsComponent from './FeaturedItemsComponent'


const FeaturedItems = () => {
  const [data,setData] = useState([
    { 
      id: 1,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_be5de627-ef45-49ac-a3cc-62251aea8735.png?v=1676382154&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_7d93a28d-7eee-48f2-ac18-471017801672.png?v=1676382155&width=140'
    },
    { 
      id: 2,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_48952485-b78f-4fe7-aeb5-5e6f9da9432f.png?v=1676887305&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_e3f449de-0889-4d39-9640-f2ae965ab21a.png?v=1676887305&width=140',
    },
    { 
      id: 3,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_0ca7ac96-3149-4175-987b-fd1ad1330936.png?v=1676887836&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_77d34400-2af2-42c5-af39-0cfe2057fbab.png?v=1676887835&width=140',
    },
    { 
      id: 4,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_c8200496-0def-4ee2-8a29-2fe5cd60b1db.png?v=1675499179&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_c8200496-0def-4ee2-8a29-2fe5cd60b1db.png?v=1675499179&width=140',
      
    },
    { 
      id: 5,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1.png?v=1676886652&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2.png?v=1676886653&width=140',
    },
    { 
      id: 6,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_f1f96594-90ad-46ab-b2c5-d524966df5df.png?v=1675513177&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_53e9cb42-d9be-44f2-8f9c-c5bbadb9b2fd.png?v=1675513595&width=140',
    },
    { 
      id: 7,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_04d1d44d-0c42-4d4e-a849-0a1394aeaa08.png?v=1676887924&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_148897a9-f0d3-46b8-b6ad-9c7bd7c82dc5.png?v=1676887925&width=140',
    },
    { 
      id: 8,
      name:'ბოტასი',
      price:'75.00',
      src1:'https://cdn.shopify.com/s/files/1/1323/8419/products/1_a36ca79c-2fc5-4937-969b-a6329c95790c.png?v=1676383470&width=140',
      src2:'https://cdn.shopify.com/s/files/1/1323/8419/products/2_7dee9018-3f72-4c85-8fb0-05517a4b02a5.png?v=1676383469&width=140',
    },
  ])
  return (
    <div className="w-full h-auto justify-center py-10 px-[10%]">
      <div className='flex flex-wrap justify-between w-full h-auto'>
        {data.map((item) => (
          <FeaturedItemsComponent item={item}/>
        ))}
      </div>
      
    </div>
  )
}

export default FeaturedItems