import { useSpring, animated } from "react-spring";
import { useRef } from "react";
import useIntersectionObserver from "../CustomHooks/IntObserver";

const SlideFromBottom = ({ children, dur=500, position=200,test1}) => {
  //object ref
  const wholeDivRef = useRef(null);
  //ref intersection observer
  const dataRef = useIntersectionObserver(wholeDivRef, {
    freezeOnceVisible: true,
  });

  //animation
  const props = useSpring({
    config: { duration: dur },
    from: {
      opacity: 0,
      y: position,
    },
    to: {
      opacity: dataRef?.isIntersecting ? 1 : 0,
      y: dataRef?.isIntersecting ? 0 : position,
    },
  });

  return (
    <animated.div style={props}>
      <div ref={wholeDivRef} className={test1}>{children}</div>
    </animated.div>
  );
};

export default SlideFromBottom;
