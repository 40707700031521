import React, { useState } from 'react'
import {MdStarRate} from 'react-icons/md'
const WhatPeopleSayComponent = ({item}) => {
  const [changeImg,setChangeImg] = useState(item.src)
  return (
    <div 
      className='w-[32%] h-auto flex flex-col py-[45px] px-[28px] bg-slate-200 rounded-xl gap-7'>
      <div className='flex items-center flex-col gap-2'>
        <h1 className='text-lg font-semibold'>{item.name}</h1>
        <p>{item.type}</p>
        <div className='flex gap'>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
          <MdStarRate/>
        </div>
      </div>
      <div className="flex justify-center w-full ">
        <div className="w-[50%] h-[150px] bg-slate-200 flex justify-center"
          onMouseOver={() => setChangeImg(item.src1)}
          onMouseOut={() => setChangeImg(item.src)}
        > 
          
          <img 
            className='bg-slate-200 w-full h-full'
            src={changeImg} alt=''/>
        </div>
      </div>
      <h1 className="text-center text-lg text-slate-500">{item.name1}</h1>
      <p className="text-center text-slate-500">
        {item.description}
      </p>
    </div>
  )
}

export default WhatPeopleSayComponent