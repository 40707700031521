import React, { useState } from 'react'

const NewSale = () => {
  const [mode,setMode] = useState('Dark')
  return (
    <div className="w-72 h-auto flex bg-white border-[2px] border-slate-100 rounded-full items-center ">
      <button
        onClick={() => setMode('Dark')}
        className={`w-1/2 text-lg duration-300  ${mode==='Dark'? 'bg-blue-700 text-white' : 'bg-white text-black'} rounded-2xl py-[5px]`}>ქალი</button>
      <button
        onClick={() => setMode('Light')}  
        className={`w-1/2 text-lg duration-300 ${mode==='Light'? 'bg-blue-700 text-white' : 'bg-white text-black'} rounded-2xl py-[5px]`}>კაცი</button>
    </div>
  )
}
export default NewSale