import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination,Autoplay } from "swiper";
import SlideFromBottom from '../Animations/SlideFromBottom';


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Slider = () => {
  return (
    <>
    <Swiper
      spaceBetween={30}
      // slidesPerView={2}
      effect={"fade"}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[EffectFade, Pagination,Navigation,Autoplay]}
      className="mySwiper w-full h-[800px] bg-slate-100"
    >
      <SwiperSlide>
        <div className=' cover1 w-full h-full flex items-center'>
          <div className='w-[50%] h-full'></div>
          <div className='flex flex-col gap-5 w-[50%]'>
            <SlideFromBottom dur={200} position={-100} >
              <h1 className='text-[50px] text-white '>მიიღე გრანდიოზული</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={700} position={-50}>
              <h1 className='text-[50px] text-white '>ფასდაკლება</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={2000} position={50}>
              <button className='bg-white py-3 w-[20%] ml-2 text-black hover:bg-black hover:text-white text-sm  rounded-full'>სრულად ნახვა</button>
            </SlideFromBottom>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='cover1_1 w-full h-full flex items-center'>
          <div className='w-[50%] h-full'></div>
          <div className='flex flex-col gap-5 w-[50%]'>
            <SlideFromBottom dur={200} position={-100} >
              <h1 className='text-[50px] text-white '>მიიღეთ ქეშბექი</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={700} position={-50}>
              <h1 className='text-[50px] text-white '>სრულ კოლექციაზე</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={2000} position={50}>
              <button className='bg-white py-3 w-[20%] ml-2 text-black hover:bg-black hover:text-white text-sm  rounded-full'>კოლექციები</button>
            </SlideFromBottom>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='cover1_2 w-full h-full flex items-center'>
          <div className='w-[50%] h-full'></div>
          <div className='flex flex-col gap-5 w-[50%]'>
            <SlideFromBottom dur={200} position={-100} >
              <h1 className='text-[50px] text-white '>მიიღეთ ქეშბექი</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={700} position={-50}>
              <h1 className='text-[50px] text-white '>სრულ კოლექციაზე</h1>
            </SlideFromBottom>
            <SlideFromBottom dur={2000} position={50}>
              <button className='bg-white py-3 w-[20%] ml-2 text-black hover:bg-black hover:text-white text-sm  rounded-full'>კოლექციები</button>
            </SlideFromBottom>
          </div>
        </div>
      </SwiperSlide>
      
      
    </Swiper>
  </>
  )
}

export default Slider