import React from 'react'
import {BsArrowRightShort} from 'react-icons/bs'
import {IoMdArrowDropright} from 'react-icons/io'

const FoodAdditives = () => {
  return (
    <div className='w-0 h-[250px] group-hover:overflow-visible duration-500 bg-white rounded-lg group-hover:w-full group-hover:border-2 border-slate-200 absolute left-[100%] top-[35%] z-[5]'>
      <div className='w-full h-auto overflow-hidden group-hover:overflow-visible'>
        <div className='w-full h-auto relative flex flex-col gap-2 py-5'>
          {/* <h1 className='text-sm px-5'>მამაკაცებისთვის</h1> */}
          <div className='flex justify-between  items-center group-one px-5  '>
            <h1 className='text-sm'>ამინომჟავა</h1>
            <BsArrowRightShort className='text-black w-7 h-7 '/>
            <div className='absolute w-0 h-[1/2] group-one-hover:w-full duration-100 rounded-r-lg  left-[100%] z-[70] top-[20%] overflow-hidden'>
              <div className='flex flex-col w-[70%] h-full bg-white group-one-hover:border group-one-hover:border-slate-200  rounded-lg py-7 px-5'>
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>BCAA</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>არგინინი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>გლუტამინი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>სხვა ამინომჟავები</p>
                </div>    
              </div>
            </div>
          </div>
          <div className='flex justify-between  items-center group-one px-5  '>
            <h1 className='text-sm'>პროტეინი</h1>
            <BsArrowRightShort className='text-black w-7 h-7 '/>
            <div className='absolute w-0 h-[1/2] group-one-hover:w-full duration-100 rounded-r-lg  left-[100%] z-[70] top-[50%] overflow-hidden'>
              <div className='flex flex-col w-[70%] h-full bg-white group-one-hover:border group-one-hover:border-slate-200  rounded-lg py-7 px-5'>
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>მიქსი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>იზოლატი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>კონცენტრატი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>კაზეინი</p>
                </div>    
              </div>
            </div>
          </div>
          <div className='flex justify-between  items-center group-one px-5  '>
            <h1 className='text-sm'>კერატინი</h1>
            <BsArrowRightShort className='text-black w-7 h-7 '/>
            <div className='absolute w-0 h-[1/2] group-one-hover:w-full duration-100 rounded-r-lg  left-[100%] z-[70] top-[70%] overflow-hidden'>
              <div className='flex flex-col w-[70%] h-full bg-white  group-one-hover:border group-one-hover:border-slate-200  rounded-lg py-7 px-5'>
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>მონოჰიდრატი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>კომპლექი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>მონოჰიდრატი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>ლომპლექსი</p>
                </div>    
              </div>
            </div>
          </div>
          <div className='flex justify-between  items-center group-one px-5  '>
            <h1 className='text-sm'>პირის ღრუს მოვლა</h1>
            <BsArrowRightShort className='text-black w-7 h-7 '/>
            <div className='absolute w-0 h-[1/2] group-one-hover:w-full  duration-100 rounded-r-lg  left-[100%] z-[70] top-[70%] overflow-hidden'>
              <div className='flex flex-col w-[70%] h-full group-one-hover:border group-one-hover:border-slate-200 bg-white gap  rounded-lg py-7 px-5'>
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>ნიღბები</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>სახის კრემი</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>ნიღბები</p>
                </div>    
                <div className='cursor-pointer flex hover:text-blue-600 items-center gap-1 hover:gap-0 hover:pl-3 duration-300'>
                  <IoMdArrowDropright />
                  <p className='text-sm'>ნიღბები</p>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FoodAdditives