import React from 'react'
import WhiteLogo from '../image/logo.png'
import {BsInstagram,BsTwitter,BsYoutube} from 'react-icons/bs'
import {FaTiktok,FaGooglePay,FaApplePay} from 'react-icons/fa'
import {GrAmex} from 'react-icons/gr'
import {ImFacebook} from 'react-icons/im'
import {RiVisaLine} from 'react-icons/ri'
import {SiAmazonpay} from 'react-icons/si'
const ElectronicsFooter = () => {
  return (
    <div className='w-full flex flex-col bg-blue-600 '>
      <div className='w-full h-auto flex items-center justify-between px-[10%] py-14 '>
        <div className='w-full h-auto flex justify-between '>
          <div className='w-[33%] flex flex-col gap-7' >
            <h1 className='uppercase text-white'>ინფორმაცია</h1>
            <div className='w-full flex flex-col text-white gap-3 '>
              <p>ჩვენ შესახებ</p>
              <p>ძებნა</p>
              <p>კონტაქტი</p>
              <p>სიახლეები</p>
            </div>
          </div>
          <div className='w-[33%] flex flex-col gap-7' >
            <h1 className='uppercase text-white'>დამატებითი ბმულები</h1>
            <div className='w-full flex flex-col text-white gap-3 '>
              <p>კონფიდენციალურობის პოლიტიკა</p>
              <p>წესები და პირობები</p>
              <p>დოკუმენტაცია</p>
              <p>საგადახდო სისტემები</p>
              <p>მიწოდების პოლიტიკა</p>
            </div>
          </div>
          <div className='w-[33%] h-full flex flex-col gap-7 justify-start' >
            <h1 className='uppercase text-white'>კონტაქტები</h1>
            <div className='w-full flex flex-col text-white gap-3 '>
              <p>ტელეფონი: <a>2 11 11 90</a></p>
              <p>ელ ფოსტა: <a>Sales@Bmsoft.dev</a></p>
              <p>მისამართი: <a>წერეთლის გამზირი #116, თბილისი</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full flex py-3 justify-between items-center border-t border-white px-[10%] bg-white'>
        <h1 className='text-black hover:text-blue-600 cursor-pointer'>© 2023, SUPPORTED BY BMSOFT</h1>
        <div className='flex gap-5'>
          <div className='w-[25px] h-[25px] text-black hover:text-blue-600 cursor-pointer'>
            <BsInstagram className='w-full h-full '/>
          </div>
          <div className='w-[25px] h-[25px] text-black hover:text-blue-600 cursor-pointer'>
            <ImFacebook className='w-full h-full '/>
          </div>
          <div className='w-[25px] h-[25px] text-black hover:text-blue-600 cursor-pointer'>
            <FaTiktok className='w-full h-full '/>
          </div>
          <div className='w-[25px] h-[25px] text-black hover:text-blue-600 cursor-pointer'>
            <BsTwitter className='w-full h-full '/>
          </div>
          <div className='w-[25px] h-[25px] text-black hover:text-blue-600 cursor-pointer'>
            <BsYoutube className='w-full h-full '/>
          </div>
        </div>
        <div className='flex gap-2'>
          <div className='h-[30px] w-[40px] bg-blue-700 flex items-center justify-center rounded-md' >
            <GrAmex className='w-[80%] h-[80%] text-white'/>
          </div>
          <div className='h-[30px]  w-[40px] bg-white border border-black flex items-center justify-center rounded-md' >
            <FaApplePay className='w-[80%] h-[80%] text-black'/>
          </div>
          <div className='h-[30px]  w-[40px] bg-white flex items-center justify-center rounded-md border-2 border-slate-300' >
            <FaGooglePay className='w-[80%] h-[80%]'/>
          </div>
          <div className='h-[30px] w-[40px] bg-white flex items-center justify-center rounded-md border-2 border-slate-300' >
            <SiAmazonpay className='w-[80%] h-[80%]'/>
          </div>
          <div className='h-[30px]  w-[40px] bg-white flex items-center justify-center rounded-md border-2 border-slate-300' >
            <RiVisaLine className='w-[80%] h-[80%] text-blue-950'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ElectronicsFooter

