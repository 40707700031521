import React from "react";

const CheckBox = ({title}) => {
  return (
    <div className="flex w-full h-auto justify-between flex-col gap-2 px-3">
      <div className="flex justify-between">
        <label className="flex">
          <div>
            <input className="mr-5 rounded-full" type="checkbox" />
            {title}
          </div>
        </label>
      </div>
    </div>
  );
};

export default CheckBox;
