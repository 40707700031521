import React from 'react'
import Header from '../Header'
import FirstBanners from './Firstbanners'
import Category from './Category'
import FeaturedItems from './featuredItemsComponents'
import Boots from './bootsComponents'
import ClotherSlider from './clotherComponents'
import Brands from './Brands'
import SlideFromBottom from '../Animations/SlideFromBottom'
import FooterThree from './FooterThree'
import FullDiv from './FullDiv'


const HomePageThird = () => {
  return (
    <div className='w-ful h-auto'>
      <Header/>
      <SlideFromBottom>
        <FirstBanners/>
      </SlideFromBottom>
      <SlideFromBottom>
        <Category/>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className='flex flex-col gap-10 w-full h-auto py-20'>
          <h1 className='text-2xl text-center'>ახალი დამატებული</h1>
          <SlideFromBottom>
            <ClotherSlider/>  
          </SlideFromBottom>
        </div>
      </SlideFromBottom>
      <SlideFromBottom>
        <FullDiv/>
      </SlideFromBottom>
      <SlideFromBottom>
        <div className='flex flex-col gap-5 w-full h-auto pt-10'>
          <h1 className='text-2xl text-center'>რეკომენდებული</h1>
          <SlideFromBottom>
            <FeaturedItems/>
          </SlideFromBottom>
        </div>
      </SlideFromBottom>
        <div className='flex flex-col gap-7 py-14 items-center justify-center' >
          <h1 className='text-center text-2xl text-slate-700'>მამაკაცისთვის</h1>
            <Boots/>
        </div>
      <div className="flex flex-col gap-10 w-full h-auto py-20">
        <h1 className='text-2xl text-center'>ბრენდები</h1>
        <Brands/>
      </div>
      <FooterThree/>
    </div>
  )
}

export default HomePageThird