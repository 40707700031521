import React, { useState } from 'react'
import SlideFromBottom from '../Animations/SlideFromBottom'
const OurLookbook = () => {
  const [first_first_show,setFirst_first_show] = useState(false)
  const [first_second_show,setFirst_second_show] = useState(false)
  const [first_three_show,setFirst_three_show] = useState(false)
  const [second_first_show,setSecond_first_show] = useState(false)
  const [second_second_show,setSecond_second_show] = useState(false)
  const [second_three_show,setSecond_three_show] = useState(false)
  const [three_first_show,setThree_first_show] = useState(false)
  const [three_second_show,setThree_second_show] = useState(false)
  const [three_three_show,setThree_three_show] = useState(false)
  const [three_four_show,setThree_four_show] = useState(false)
  const [three_five_show,setThree_five_show] = useState(false)
  const [changeSrc,setChangeSrc] = useState('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')

  return (
    <SlideFromBottom>
      <div className='w-full h-auto px-5 py-32 '>
        <div className='w-full h-auto gap-3 flex'>
          <div className='w-[33%] h-[370px] flex'>
            <div className={`flex flex-col absolute ml-[9%] mt-[8%]  w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setFirst_first_show((prev) => prev= !prev); setFirst_second_show(false); setFirst_three_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
              </div>
              {
                first_first_show && 
                <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                  <img className='w-full h-[60%] rounded-md' 
                  src={changeSrc} 
                  onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                  onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                  alt=''/>
                  <h1 className='text-sm'>კაცის მაისური</h1>
                  <div className='flex gap-1 items-center'>
                    <div className='flex items-end'>
                      <p className='text-sm'>$</p>
                      <h1 className=' text-xl'>39.00</h1>
                    </div>
                    <div className='flex items-end line-through'>
                      <p className='text-[8px]'>$</p>
                      <h1 className='text-xs'>39.00</h1>
                    </div>
                  </div>
                  <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                </div>
              }
            </div>
            <div className={`flex flex-col-reverse absolute ml-[11%] ${first_second_show ? 'mt-[8px]':"mt-[14%]"}  w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center z-[1] '>
                <div
                  onClick={() => {setFirst_second_show((prev) => prev= !prev); setFirst_first_show(false); setFirst_three_show(false)}} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
                </div>
                  {
                    first_second_show && 
                    <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                      <img className='w-full h-[60%] rounded-md' 
                      src={changeSrc} 
                      onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                      onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                      alt=''/>
                      <h1 className='text-sm'>კაცის მაისური</h1>
                      <div className='flex gap-1 items-center'>
                        <div className='flex items-end'>
                          <p className='text-sm'>$</p>
                          <h1 className=' text-xl'>39.00</h1>
                        </div>
                        <div className='flex items-end line-through'>
                          <p className='text-[8px]'>$</p>
                          <h1 className='text-xs'>39.00</h1>
                        </div>
                      </div>
                      <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                    </div>
                  }
            </div>
            <div className={`flex flex-col-reverse absolute ml-[8%] ${first_three_show ? 'mt-[4%]':"mt-[22%]"}  w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setFirst_three_show((prev) => prev= !prev); setFirst_first_show(false); setFirst_second_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
                </div>
                  {
                    first_three_show && 
                    <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                      <img className='w-full h-[60%] rounded-md' 
                      src={changeSrc} 
                      onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                      onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                      alt=''/>
                      <h1 className='text-sm'>კაცის მაისური</h1>
                      <div className='flex gap-1 items-center'>
                        <div className='flex items-end'>
                          <p className='text-sm'>$</p>
                          <h1 className=' text-xl'>39.00</h1>
                        </div>
                        <div className='flex items-end line-through'>
                          <p className='text-[8px]'>$</p>
                          <h1 className='text-xs'>39.00</h1>
                        </div>
                      </div>
                      <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                    </div>
                  }
            </div>
            <img className='w-full h-full rounded-2xl' 
            src='https://cdn.shopify.com/s/files/1/1323/8419/files/lookbook-01_c83ee3c1-f925-43db-8db8-0c90f2734a17.png?v=1664378530&width=610' 
            alt=''/>
          </div>
          <div className='w-[33%]  h-[370px] flex'>
            <div className={`flex flex-col  absolute ml-[15%] mt-[10%] w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setSecond_first_show((prev) => prev= !prev); setSecond_second_show(false);setSecond_three_show(false)}} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
              </div>
              {
                second_first_show && 
                <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                  <img className='w-full h-[60%] rounded-md' 
                  src={changeSrc} 
                  onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                  onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                  alt=''/>
                  <h1 className='text-sm'>კაცის მაისური</h1>
                  <div className='flex gap-1 items-center'>
                    <div className='flex items-end'>
                      <p className='text-sm'>$</p>
                      <h1 className=' text-xl'>39.00</h1>
                    </div>
                    <div className='flex items-end line-through'>
                      <p className='text-[8px]'>$</p>
                      <h1 className='text-xs'>39.00</h1>
                    </div>
                  </div>
                  <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                </div>
                }
            </div>
            <div className={`flex flex-col  absolute ml-[11%] mt-[13%] w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setSecond_second_show((prev) => prev= !prev); setSecond_first_show(false);setSecond_three_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
              </div>
              {
                second_second_show && 
                <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                  <img className='w-full h-[60%] rounded-md' 
                  src={changeSrc} 
                  onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                  onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                  alt=''/>
                  <h1 className='text-sm'>კაცის მაისური</h1>
                  <div className='flex gap-1 items-center'>
                    <div className='flex items-end'>
                      <p className='text-sm'>$</p>
                      <h1 className=' text-xl'>39.00</h1>
                    </div>
                    <div className='flex items-end line-through'>
                      <p className='text-[8px]'>$</p>
                      <h1 className='text-xs'>39.00</h1>
                    </div>
                  </div>
                  <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                </div>
                }
            </div>
            <div className={`flex flex-col-reverse absolute ml-[12%] ${second_three_show ? 'mt-[8%]':"mt-[21%]"}  w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setSecond_three_show((prev) => prev= !prev); setSecond_first_show(false); setSecond_second_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
                </div>
                  {
                    second_three_show && 
                    <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                      <img className='w-full h-[60%] rounded-md' 
                      src={changeSrc} 
                      onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                      onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                      alt=''/>
                      <h1 className='text-sm'>კაცის მაისური</h1>
                      <div className='flex gap-1 items-center'>
                        <div className='flex items-end'>
                          <p className='text-sm'>$</p>
                          <h1 className=' text-xl'>39.00</h1>
                        </div>
                        <div className='flex items-end line-through'>
                          <p className='text-[8px]'>$</p>
                          <h1 className='text-xs'>39.00</h1>
                        </div>
                      </div>
                      <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                    </div>
                  }
            </div>
            <img className='w-full h-full rounded-2xl' src='https://cdn.shopify.com/s/files/1/1323/8419/files/lookbook-02_47dd79d8-4c2d-4715-af03-43876624ed8d.png?v=1665218025&width=610' alt=''/>
          </div>
          <div className='w-[33%]  h-[370px] flex'>
            <div className={`flex flex-col  absolute ml-[20%] mt-[5%] w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setThree_first_show((prev) => prev= !prev); 
                    setThree_second_show(false);setThree_three_show(false);
                    setThree_four_show(false);setThree_five_show(false)}} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
              </div>
              {
                three_first_show && 
                <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                  <img className='w-full h-[60%] rounded-md' 
                  src={changeSrc} 
                  onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                  onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                  alt=''/>
                  <h1 className='text-sm'>კაცის მაისური</h1>
                  <div className='flex gap-1 items-center'>
                    <div className='flex items-end'>
                      <p className='text-sm'>$</p>
                      <h1 className=' text-xl'>39.00</h1>
                    </div>
                    <div className='flex items-end line-through'>
                      <p className='text-[8px]'>$</p>
                      <h1 className='text-xs'>39.00</h1>
                    </div>
                  </div>
                  <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                </div>
                }
            </div>
            <div className={`flex flex-col  absolute ml-[12%] mt-[10%] w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setThree_second_show((prev) => prev= !prev); setThree_first_show(false);
                    setThree_three_show(false);setThree_four_show(false);setThree_five_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
              </div>
              {
                three_second_show && 
                <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                  <img className='w-full h-[60%] rounded-md' 
                  src={changeSrc} 
                  onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                  onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                  alt=''/>
                  <h1 className='text-sm'>კაცის მაისური</h1>
                  <div className='flex gap-1 items-center'>
                    <div className='flex items-end'>
                      <p className='text-sm'>$</p>
                      <h1 className=' text-xl'>39.00</h1>
                    </div>
                    <div className='flex items-end line-through'>
                      <p className='text-[8px]'>$</p>
                      <h1 className='text-xs'>39.00</h1>
                    </div>
                  </div>
                  <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                </div>
                }
            </div>
            <div className={`flex flex-col-reverse absolute ml-[2%] ${three_three_show ? 'mt-[9%]':"mt-[22%]"}  w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setThree_three_show((prev) => prev= !prev); setThree_first_show(false); 
                    setThree_second_show(false);setThree_four_show(false);setThree_five_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
                </div>
                {
                  three_three_show && 
                  <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                  <img className='w-full h-[60%] rounded-md' 
                  src={changeSrc} 
                  onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                  onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                  alt=''/>
                  <h1 className='text-sm'>კაცის მაისური</h1>
                  <div className='flex gap-1 items-center'>
                    <div className='flex items-end'>
                      <p className='text-sm'>$</p>
                      <h1 className=' text-xl'>39.00</h1>
                    </div>
                    <div className='flex items-end line-through'>
                      <p className='text-[8px]'>$</p>
                      <h1 className='text-xs'>39.00</h1>
                    </div>
                  </div>
                  <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                </div>
                }
            </div>
            <div className={`flex flex-col-reverse absolute ml-[20%] ${three_four_show ? 'mt-[8%]':"mt-[21%]"}  w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setThree_four_show((prev) => prev= !prev) ;setThree_first_show(false); 
                    setThree_second_show(false);setThree_three_show(false);setThree_five_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
                </div>
                {
                  three_four_show && 
                  <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                    <img className='w-full h-[60%] rounded-md' 
                    src={changeSrc} 
                    onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                    onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                    alt=''/>
                    <h1 className='text-sm'>კაცის მაისური</h1>
                    <div className='flex gap-1 items-center'>
                      <div className='flex items-end'>
                        <p className='text-sm'>$</p>
                        <h1 className=' text-xl'>39.00</h1>
                      </div>
                      <div className='flex items-end line-through'>
                        <p className='text-[8px]'>$</p>
                        <h1 className='text-xs'>39.00</h1>
                      </div>
                    </div>
                    <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                  </div>
                }
            </div>
            <div className={`flex flex-col absolute ml-[1%] mt-[7%] w-[150px] items-center gap-1`}>
              <div className='w-[30px] h-[30px] rounded-full group flex  bg-white  items-center justify-center '>
                <div
                  onClick={() => {setThree_five_show((prev) => prev= !prev) ;setThree_first_show(false); 
                    setThree_second_show(false);setThree_three_show(false);setThree_four_show(false) }} 
                  className={`w-[50%] h-[50%] rounded-full bg-green-500 group-hover:w-[70%] group-hover:h-[70%] group-hover:bg-blue-500`}></div>
                </div>
                {
                  three_five_show && 
                  <div className='w-[170px] h-[300px] bg-white rounded-xl items-center flex flex-col px-2 py-2 gap-2 z-[3]'>
                    <img className='w-full h-[60%] rounded-md' 
                    src={changeSrc} 
                    onMouseOver={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/4_e0eda3e8-948a-4393-8196-43afe112b6ec.png?v=1674488415&width=184')}
                    onMouseOut={() => setChangeSrc('https://cdn.shopify.com/s/files/1/1323/8419/products/5_ae33bea8-08bf-4ff0-9d0f-c7cd96e3afad.png?v=1674415501&width=184')}
                    alt=''/>
                    <h1 className='text-sm'>კაცის მაისური</h1>
                    <div className='flex gap-1 items-center'>
                      <div className='flex items-end'>
                        <p className='text-sm'>$</p>
                        <h1 className=' text-xl'>39.00</h1>
                      </div>
                      <div className='flex items-end line-through'>
                        <p className='text-[8px]'>$</p>
                        <h1 className='text-xs'>39.00</h1>
                      </div>
                    </div>
                    <button className='bg-blue-500 text-white  uppercase rounded-full text-[10px] py-[6px] w-[50%] '>ნახვა</button>
                  </div>
                }
            </div>
            <img className='w-full h-full rounded-2xl' src='https://cdn.shopify.com/s/files/1/1323/8419/files/lookbook-03_04f944a1-7ad9-4f46-be80-cc5bd50ef1b0.png?v=1665218040&width=610' alt=''/>
          </div>
        </div>
      </div>
    </SlideFromBottom>
  )
}

export default OurLookbook