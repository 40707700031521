import React from 'react'
import SlideFromBottom from '../Animations/SlideFromBottom'
const CategoryLast = () => {
  return (
    <SlideFromBottom>
      <div className='w-full h-auto px-[15%] pb-[100px] pt-[70px] flex justify-center gap-5'>
        <div className='flex w-[24%] flex-col gap-[2px] items-center hover:text-blue-700 duration-300 cursor-pointer group' >
          <div className='w-full h-[300px] flex justify-center items-center'>
            <img className='rounded-full w-[90%] group-hover:w-full duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-01.png?v=1664378400&width=510' alt=''/>
          </div>
          <h1 className='text-2xl'>კაცი</h1>
        </div>
        <div className='flex w-[24%] flex-col gap-[2px] items-center  hover:text-blue-700 duration-300 cursor-pointer' >
          <div className='w-full h-[300px] flex justify-center items-center group'>
            <img className='rounded-full w-[90%] group-hover:w-full duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-02_6a81e2fa-f772-4324-b614-5d88f3dfa55e.png?v=1669885002&width=510' alt=''/>
          </div>
          <h1 className='text-2xl '>ქალი</h1>
        </div>
        <div className='flex w-[24%] flex-col gap-[2px] items-center  hover:text-blue-700 duration-300 cursor-pointer' >
          <div className='w-full h-[300px] flex justify-center items-center group'>
            <img className='rounded-full w-[90%] group-hover:w-full duration-300'src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-03_128b8298-f423-488b-b98f-4fb8668411f1.png?v=1664561733&width=510' alt=''/>
          </div>
          <h1 className='text-2xl '>აქსესუარები</h1>
        </div>
        <div className='flex flex-col w-[24%] gap-[2px] items-center  hover:text-blue-700 duration-300 cursor-pointer' >
          <div className='w-full h-[300px] flex justify-center items-center group'>
            <img className='rounded-full w-[90%] group-hover:w-full duration-300' src='https://cdn.shopify.com/s/files/1/1323/8419/files/category-circle-04_a394c379-2fd9-4600-9a8f-869836018098.png?v=1664561748&width=510' alt=''/>
          </div>
          <h1 className='text-2xl '>კაბები</h1>
        </div>
      </div>
    </SlideFromBottom>
  )
}

export default CategoryLast