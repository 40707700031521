import React from 'react'

const FullDiv = () => {
  return (
    <div className='w-full h-auto py-10'>
      <div className='w-full h-auto flex'>
        <div className='relative w-1/3 flex justify-center items-center'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/banner-03_919de8a2-3bf8-4b9c-a78b-0fadbe4c02e7.png?v=1664654397&width=1132' alt=''/>
          <div className='absolute w-[50%] h-auto'>
            <div className='w-full py-2 px-5 bg-white h-auto flex flex-col gap-1'>
              <h1 className='text-center texts-xs'>ფასდაკლება</h1>
              <p className='text-[10px] text-slate-700'>შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და</p>
            </div>
          </div>
        </div>
        <div className='relative w-1/3 flex justify-center items-center gap-1'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/banner-04_a25f1594-54ed-4a79-a5bd-09d2d4c02a34.png?v=1664654410&width=1132' alt=''/>
          <div className='absolute w-[50%] h-auto'>
            <div className='w-full py-2 px-5 bg-white h-auto flex flex-col'>
              <h1 className='text-center texts-xs'>შექმენი შენი სტილი</h1>
              <p className='text-[10px] text-slate-700'>შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და</p>
            </div>
          </div>
        </div>
        <div className='relative w-1/3 flex justify-center items-center gap-1'>
          <img src='https://cdn.shopify.com/s/files/1/1323/8419/files/banner-05.png?v=1664654420&width=1132' alt=''/>
          <div className='absolute w-[50%] h-auto'>
            <div className='w-full py-2 px-5 bg-white h-auto flex flex-col'>
              <h1 className='text-center texts-xs'>ფასდაკლება</h1>
              <p className='text-[10px] text-slate-700'>შემთხვევითად გენერირებული ტექსტი ეხმარება დიზაინერებს და</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullDiv